import styled from "styled-components";

import Box from "./Box";

export const Container = styled(Box)`
  display: flex;
`;

export const GridContainer = styled.div`
  display: grid;
`;
