import styled, { css } from "styled-components";

import { RadioButton } from "../RadioButton";

export type Props = {
  children: React.ReactNode;
  name: string;
  onSelect: (value: string) => void;
  selected?: boolean;
  value: string;
};

export function SelectOption(props: Props): JSX.Element {
  const { children, name, onSelect, selected, value } = props;

  return (
    <Label role="option" aria-selected={selected} tabIndex={0}>
      <RadioButton
        autoFocus={selected}
        checked={selected}
        name={name}
        value={value}
        onChange={() => onSelect(value)}
      />
      {children}
    </Label>
  );
}

/* stylelint-disable scale-unlimited/declaration-strict-value */
const Label = styled.label`
  ${({ theme }) => css`
    font: ${theme.fontStudioBodyCopySmallShortHand};
    letter-spacing: ${theme.fontStudioBodyCopySmallLetterSpacing};
    padding: 4px;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    color: ${theme.colorBase800};
    cursor: pointer;
    border-radius: ${theme.borderRadius600};

    :hover {
      background-color: ${theme.colorBase100};
    }
  `}
`;
