import styled, { css } from "styled-components";

import { Container, GridContainer } from "../Container";
import {
  CONTAINER_PADDING,
  CONTENT_ITEM_BORDER_SIZE,
  PAGE_MAX_WIDTH,
} from "./constants";

type PageContentProps = React.ComponentProps<typeof Content>;

export function PageContent({ children, ...props }: PageContentProps) {
  return (
    <ContentContainer>
      <Content {...props}>{children}</Content>
    </ContentContainer>
  );
}

const ContentContainer = styled(Container)`
  /* z-index is set so the sticky PageHeader component will always be on top of
  the content */
  z-index: 1;
  grid-area: content;
  flex-direction: column;
  padding: 0 ${CONTAINER_PADDING} ${CONTAINER_PADDING};
`;

const Content = styled(GridContainer)`
  width: 100%;
  max-width: ${PAGE_MAX_WIDTH};
  align-self: center;
`;

export const negativeContentMarginsCss = css`
  margin: 0 -${CONTENT_ITEM_BORDER_SIZE};
`;

export const PageContentList = styled(PageContent)`
  margin: -${CONTENT_ITEM_BORDER_SIZE};
  row-gap: ${CONTENT_ITEM_BORDER_SIZE};
`;

export const PageContentItem = styled(GridContainer)(
  ({ theme }) => css`
    cursor: pointer;
    border: ${CONTENT_ITEM_BORDER_SIZE} solid transparent;
    border-radius: ${theme.borderRadius600};
    margin: 0 -${CONTENT_ITEM_BORDER_SIZE};

    &:hover {
      background-color: ${theme.colorBase100};
      border-color: ${theme.colorBase100};
    }
  `
);
