import { HTMLAttributes, useContext, useEffect, useRef } from "react";
import useClickAway from "react-use/lib/useClickAway";
import styled, { css, useTheme } from "styled-components";

import { useHandler, useKeyPressEvent } from "../../../hooks";
import { ModalContext } from "./ModalContext";
import { Icon, IconType } from "../Icon";
import { ButtonBar, IconButton } from "../buttons";

const MAX_WIDTH = "600px";

type BaseModalProps = {
  "data-testid"?: string;
} & HTMLAttributes<HTMLDivElement>;

/**
 * Modal to be used when composing custom modals.
 */
export function BaseModal({
  "data-testid": testId = "modal",
  children,
  ...attributes
}: BaseModalProps) {
  const { requestClose, afterClose } = useContext(ModalContext);
  const ref = useRef<HTMLDivElement>(null);
  const onClickAway = useHandler((event: Event) => {
    // poppers can be displayed inside the modal, so we need to check if the
    // click was on a popper before closing the modal
    const target = event.target as HTMLElement;
    if (target.offsetParent?.getAttribute("role") === "tooltip") {
      return;
    }

    requestClose();
  });

  useEffect(() => {
    return () => afterClose?.();
  }, [afterClose]);

  useClickAway(ref, onClickAway);
  useKeyPressEvent("Escape", requestClose);

  return (
    <BaseModalContainer
      data-testid={testId}
      ref={ref}
      role="modal"
      aria-modal="true"
      aria-labelledby="modal-title"
      {...attributes}
    >
      {children}
    </BaseModalContainer>
  );
}

type ModalProps = {
  description?: string;
  icon?: IconType;
  title?: string;
} & BaseModalProps;

/**
 * Modal container that accepts a title, description, icon type and renders it
 * in a consistent way with spacing & standard styles.
 */
export function Modal({
  "data-testid": testId = "modal",
  children,
  description,
  icon,
  title,
  ...attributes
}: ModalProps): JSX.Element {
  const { requestClose } = useContext(ModalContext);
  const { colorBackground } = useTheme();

  return (
    <ModalContainer data-testid={testId} {...attributes}>
      <ModalIconButton onClick={requestClose}>
        <Icon type="close" />
      </ModalIconButton>

      {icon && (
        <IconContainer>
          <Icon type={icon} color={colorBackground} />
        </IconContainer>
      )}

      {title && <Title>{title}</Title>}

      {description && <Description>{description}</Description>}

      <Content>{children}</Content>
    </ModalContainer>
  );
}

const BaseModalContainer = styled.div(
  ({ theme }) => css`
    max-width: ${MAX_WIDTH};
    min-width: min-content;
    max-height: 90vh;
    width: min(calc(100vh - 40px), calc(100vw - 40px));
    box-shadow: 0 4px 80px -10px rgb(0 0 0 / 50%);
    border-radius: 16px;
    overflow-y: auto;
    background-color: ${theme.colorBackground};

    @media (min-width: ${MAX_WIDTH}) {
      width: min(calc(100vh - 80px), calc(100vw - 80px));
    }
  `
);

const ModalContainer = styled(BaseModal)(
  ({ theme }) => css`
    display: grid;
    position: relative;
    background-color: ${theme.colorBackground};
    padding: 24px;
  `
);

const ModalIconButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const Content = styled.div`
  margin: 20px -24px 0;
  padding: 0 24px;
  overflow-x: auto;

  ${ButtonBar} {
    margin-top: 24px;
  }
`;

const Description = styled.p(
  ({ theme }) => css`
    font: ${theme.fontStudioBodyCopyRegularShortHand};
    letter-spacing: ${theme.fontStudioBodyCopyRegularLetterSpacing};
    color: ${theme.colorBase600};
    margin: 8px 0 0;
  `
);

const Title = styled.h3(
  ({ theme }) => css`
    font: ${theme.fontStudioHeadingsH3ShortHand};
    letter-spacing: ${theme.fontStudioHeadingsH3LetterSpacing};
    line-height: 24px;
    margin: 0;
  `
);

const IconContainer = styled.div`
  width: 44px;
  aspect-ratio: 1;
  background: linear-gradient(
    225deg,
    rgba(124 81 252 / 80%) 0%,
    rgba(124 81 252 / 24%) 100%
  );
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%);
  border-radius: ${({ theme }) => theme.borderRadius600};
  display: grid;
  place-items: center;
  margin-bottom: 20px;
`;
