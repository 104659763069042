import { forwardRef } from "react";
import styled from "styled-components";

const SIZE = "16px";

type Props = React.ComponentProps<typeof HiddenInput> & {
  className?: string;
};

export const RadioButton = forwardRef(function RadioButton(
  { className, ...props }: Props,
  ref: React.ForwardedRef<HTMLInputElement>
): JSX.Element {
  return (
    <Container className={className}>
      <HiddenInput {...props} ref={ref} type="radio" />
      <StyledRadioButtonDefault />
    </Container>
  );
});

const Container = styled.span`
  display: inline-block;
  position: relative;
  width: ${SIZE};
  height: ${SIZE};
`;

const HiddenInput = styled.input`
  margin: 0;
  opacity: 0;
  top: 0;
  left: 0;
  width: ${SIZE};
  height: ${SIZE};
  border-radius: ${({ theme }) => theme.borderRadiusRound};
  position: absolute;
  cursor: pointer;
`;

const StyledRadioButtonDefault = styled.div`
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.colorBase600};
  border-radius: ${({ theme }) => theme.borderRadiusRound};
  width: ${SIZE};
  height: ${SIZE};
  transition: border-color 0.1s ease-in-out;
  transition-property: border-width, border-color;

  label:hover &,
  ${/* sc-selector */ HiddenInput}:hover + & {
    border-color: ${({ theme }) => theme.colorBase700};
  }

  ${/* sc-selector */ HiddenInput}:active + & {
    border-color: ${({ theme }) => theme.colorBase800};
  }

  ${/* sc-selector */ HiddenInput}:focus-visible + &::before {
    content: "";
    position: absolute;
    width: ${SIZE};
    height: ${SIZE};
    top: 0;
    left: 0;
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    outline: ${({ theme }) => theme.colorPrimary600} solid 1px;
    border-radius: ${({ theme }) => theme.borderRadiusRound};
  }

  ${/* sc-selector */ HiddenInput}:focus-visible + & {
    outline: ${({ theme }) => theme.effectFocusOutline};
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    outline-offset: 1px;
  }

  ${/* sc-selector */ HiddenInput}:checked + & {
    border-color: ${({ theme }) => theme.colorPrimary400};
    border-width: 5px;
  }

  ${/* sc-selector */ HiddenInput}:checked:hover + & {
    border-color: ${({ theme }) => theme.colorPrimary600};
  }
`;
