import {
  AppAction,
  HideModalAction,
  HIDE_ACTIVE_CONTEXT_MENU,
  HIDE_ACTIVE_TOOLTIP,
  HIDE_ENGAGE_MENU,
  HIDE_MODAL,
  HIDE_SIDE_MENU,
  SET_ACTIVE_CONTEXT_MENU,
  SET_ACTIVE_TOOLTIP,
  SET_DRAGGED_ELEMENT,
  SET_NOTEBOOK_FOCUSED,
  SET_SIDE_MENU_BEHAVIOR,
  SET_SIDE_MENU_BUTTON_VISIBLE_OUTSIDE,
  SET_VIEWS_DISPLAY_TYPE,
  SET_WINDOW_FOCUSED,
  ShowModalAction,
  SHOW_ENGAGE_MENU,
  SHOW_MODAL,
  SHOW_SIDE_MENU,
  UpdateModalAction,
  UPDATE_MODAL,
  TOGGLE_DATE_PICKER_VISIBLE,
} from "../actions";
import type { RootState, UiState } from "../state";

export const initialState: UiState = {
  activeContextMenu: null,
  activeTooltip: null,
  dragAndDrop: { draggedElement: null },
  engageMenuIsOpen: false,
  isDatePickerOpen: false,
  isNotebookFocused: false,
  isWindowFocused: true,
  modals: new Map(),
  sideMenuBehavior: "overlay",
  sideMenuButtonVisibleOutside: false,
  sideMenuIsOpen: undefined,
  viewsDisplayType: "grid",
};

export function uiReducer(
  rootState: RootState | undefined,
  action: AppAction
): UiState {
  const state = rootState?.ui ?? initialState;

  switch (action.type) {
    case HIDE_ACTIVE_CONTEXT_MENU:
      return { ...state, activeContextMenu: null };

    case HIDE_ACTIVE_TOOLTIP:
      return { ...state, activeTooltip: null };

    case HIDE_ENGAGE_MENU:
      return { ...state, engageMenuIsOpen: false };

    case HIDE_MODAL:
      return handleHideModal(rootState, state, action);

    case HIDE_SIDE_MENU:
      return { ...state, sideMenuIsOpen: false };

    case SET_ACTIVE_CONTEXT_MENU:
      return { ...state, activeContextMenu: action.payload };

    case SET_ACTIVE_TOOLTIP: {
      const { anchor, content, options } = action.payload;
      return {
        ...state,
        activeTooltip: {
          anchor: () => anchor,
          content: () => content,
          options,
        },
      };
    }

    case TOGGLE_DATE_PICKER_VISIBLE:
      return { ...state, isDatePickerOpen: !state.isDatePickerOpen };

    case SET_DRAGGED_ELEMENT:
      return { ...state, dragAndDrop: { draggedElement: action.payload } };

    case SET_NOTEBOOK_FOCUSED:
      return { ...state, isNotebookFocused: action.payload };

    case SET_SIDE_MENU_BEHAVIOR:
      return { ...state, sideMenuBehavior: action.payload };

    case SET_SIDE_MENU_BUTTON_VISIBLE_OUTSIDE:
      return { ...state, sideMenuButtonVisibleOutside: action.payload };

    case SET_VIEWS_DISPLAY_TYPE:
      return { ...state, viewsDisplayType: action.payload };

    case SET_WINDOW_FOCUSED:
      return { ...state, isWindowFocused: action.payload };

    case SHOW_ENGAGE_MENU:
      return { ...state, engageMenuIsOpen: true };

    case SHOW_MODAL:
      return handleShowModal(state, action);

    case SHOW_SIDE_MENU:
      return { ...state, sideMenuIsOpen: true };

    case UPDATE_MODAL:
      return handleUpdateModal(state, action);

    default:
      return state;
  }
}

function handleHideModal(
  rootState: RootState | undefined,
  state: UiState,
  action: HideModalAction
): UiState {
  const modals = new Map(state.modals);
  modals.delete(action.payload.key);

  return { ...state, modals };
}

function handleShowModal(state: UiState, action: ShowModalAction): UiState {
  const { key, modal } = action.payload;

  // Any modal that gets opened should implicitly remove the focus from the
  // notebook, otherwise you could continue typing underneath the modal.
  const isNotebookFocused = false;

  const modals = state.modals.has(key)
    ? state.modals
    : new Map(state.modals).set(key, modal);

  return { ...state, isNotebookFocused, modals };
}

function handleUpdateModal(state: UiState, action: UpdateModalAction): UiState {
  const { key, modal } = action.payload;

  if (!state.modals.has(key)) {
    // eslint-disable-next-line no-console
    console.warn("Attempting to update a modal that doesn't exist!");
  }

  const modals = new Map(state.modals).set(key, modal);

  return { ...state, modals };
}
