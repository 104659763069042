import { useRef } from "react";
import styled, { css } from "styled-components";

import SparkChartIcon from "./graph.svg";
import { Container } from "./common";
import { MetricDisplay } from "./MetricDisplay";

type MetricCardProps = {
  brandColor: string;
  isActive: boolean;
  latestValue?: number | string;
  latestValueUnit?: string;
  onClickHandler: () => void;
  title: string;
};

export function MetricCard({
  brandColor,
  isActive,
  onClickHandler,
  title,
  latestValue,
  latestValueUnit,
}: MetricCardProps) {
  const ref = useRef<HTMLDivElement>(null);

  const handleOnClick = () => {
    onClickHandler();

    const cardElement = ref.current;
    if (!cardElement) {
      return;
    }

    cardElement.scrollIntoView({
      behavior: "smooth",
      inline: "center",
    });
  };

  return (
    <MetricCardContainer
      ref={ref}
      brandColor={brandColor}
      isActive={isActive}
      onClick={handleOnClick}
    >
      <MetricTitle>{title}</MetricTitle>

      <MetricDisplay
        rate={latestValue}
        unit={latestValueUnit}
        report="latest"
      />

      <SparkChartContainer>
        <SparkChartIcon />
      </SparkChartContainer>
    </MetricCardContainer>
  );
}

const MetricCardContainer = styled(Container)<
  Pick<MetricCardProps, "brandColor" | "isActive">
>(
  ({ brandColor, isActive, theme }) => css`
    --brand-color: ${brandColor};
    --spark-chart-color: var(--brand-color);
    --text-color: ${theme.colorForeground};
    --text-alternative-color: ${theme.colorBase500};

    ${isActive
      ? css`
          --spark-chart-color: ${theme.colorBackground};
          --text-color: ${theme.colorBackground};
          --text-alternative-color: rgb(255 255 255 / 60%);
        `
      : css`
          &:hover {
            background-color: ${theme.colorBase200};
          }
        `}

    cursor: pointer;
    display: grid;
    grid:
      "title title" auto
      /* 129+80+16+var(--container-spacing)=265px width as per design */
      "metric graph" 52px / 129px 80px;
    gap: 16px;
    color: var(--text-color);
    background-color: ${isActive ? "var(--brand-color)" : theme.colorBase100};
    transition: background-color 0.2s ease-in-out;
  `
);

const MetricTitle = styled.h4(
  ({ theme }) => css`
    grid-area: title;
    font: ${theme.fontStudioHeadingsH4ShortHand};
    letter-spacing: ${theme.fontStudioHeadingsH4LetterSpacing};
    margin: 0;
  `
);

export const SparkChartContainer = styled.div`
  grid-area: graph;
  color: var(--spark-chart-color);
`;
