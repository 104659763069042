import "./wdyr";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
// import { HistoryRouter as Router } from "redux-first-history/rr6";
// HACK - Use HashRouter to avoid issues when running locally
import { HashRouter as Router } from "react-router-dom";

import "./index.css";

import { addFeatureFlag, getFeatureFlagsFromSearchParams } from "./utils";
import { App } from "./components";
import store, { history } from "./store";
import { ThemeSelector } from "./theme";

// const history = createBrowserHistory();

const root = createRoot(document.querySelector("#root")!);

function start() {
  const featureFlags = getFeatureFlagsFromSearchParams();
  for (const flag of featureFlags) {
    addFeatureFlag(flag);
  }

  root.render(
    <Provider store={store}>
      <ThemeSelector>
        {/* <Router history={history}> */}
        <Router>
          <App />
        </Router>
      </ThemeSelector>
    </Provider>
  );
}

start();
