const FEATURES = [
  "assume_window_focus",
  "autometrics",
  "cloudwatch",
  "courier-workspaces",
  "delete-account",
  "https-provider",
  "sentry",
] as const;

const FEATURES_KEY = "features";

export type Feature = (typeof FEATURES)[number];

export function getFeatureFlagsFromSearchParams(): string[] {
  // Remove any redundant flags from localStorage on initial load
  removeRedundantFeatureFlags();

  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(FEATURES_KEY)?.split(",") || [];
}

export function getFeatures(): Array<Feature> {
  const data = localStorage.getItem(FEATURES_KEY);

  if (!data) {
    return [];
  }

  const flags = JSON.parse(data);

  if (!Array.isArray(flags)) {
    return [];
  }

  return flags;
}

export function addFeatureFlag(flag: string) {
  // Avoid adding unsupported flags
  if (!isSupportedFeature(flag)) {
    return;
  }

  const flags = getFeatures();

  // Avoid adding the same flag multiple times
  if (flags.includes(flag)) {
    return;
  }

  localStorage.setItem(FEATURES_KEY, JSON.stringify([...flags, flag]));
}

export function removeRedundantFeatureFlags() {
  const flags = getFeatures();
  const redundantFlags = flags.filter((flag) => !isSupportedFeature(flag));

  if (redundantFlags.length > 0) {
    localStorage.setItem(
      FEATURES_KEY,
      JSON.stringify(flags.filter((item) => !redundantFlags.includes(item)))
    );
  }
}

export function hasFeature(flag: Feature) {
  const flags = getFeatures();
  return flags.includes(flag);
}

function isSupportedFeature(feature: string): feature is Feature {
  return FEATURES.includes(feature as Feature);
}
