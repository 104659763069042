import { Prometheus } from "../services";
import { addInstance, switchInstance } from "../slices";
import { Thunk } from "../store";
import { showConfirmationDialog } from "./uiThunks";

type PrometheusResult<Result extends unknown> = {
  resultType: "matrix" | "vector" | "scalar" | "string";
  result: Result;
};

type PrometheusResponse<Data extends unknown> =
  | {
      status: "error";
      errorType: string;
      error: string;
    }
  | {
      status: "success";
      data: PrometheusResult<Data>;
      warnings: string[];
    };

// TODO - Try this when no instances are configured?
export const tryConnectingToDefaultPrometheus = (): Thunk => {
  return async (dispatch, getState) => {
    const { environment } = getState();
    if (
      environment.instances.length === 1 &&
      environment.instances[0]?.id === "demo"
    ) {
      try {
        const response = await fetch(
          "http://localhost:9090/api/v1/query?query=autometrics_check_status"
        );
        const data: PrometheusResponse<[]> = await response.json();
        if (data.status === "error") {
          throw new Error(data.error);
        }

        await Prometheus.getAllTrackedAutometricsFunctions(
          "http://localhost:9090"
        );

        dispatch(
          showConfirmationDialog("defaultProm", {
            title: "Local prometheus found",
            children:
              "You seem to be running an autometrics enabled prometheus already. Would you like to switch to it instead of the demo instance?",
            iconVisual: "info",
            onConfirm: () => {
              dispatch(
                addInstance({
                  id: "local",
                  name: "Local",
                  url: "http://localhost:9090",
                })
              );
              dispatch(switchInstance("local"));
            },
          })
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };
};
