import styled, { keyframes } from "styled-components";
import useThrottle from "react-use/lib/useThrottle";

import IconButton from "./IconButton";
import { Icon } from "../Icon";

const StyledIconButton = styled(IconButton)`
  overflow: hidden;
`;

/* stylelint-disable-next-line keyframes-name-pattern */
const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const RefreshIcon = styled(Icon).attrs({ type: "arrows_clockwise" })`
  [aria-disabled="true"] > & {
    animation: ${spinAnimation} 0.6s ease-in-out forwards infinite;
  }
`;

type Props = {
  busy?: boolean;
  "data-testid"?: string;
  className?: string;
} & Pick<React.HTMLAttributes<HTMLButtonElement>, "onClick" | "aria-label">;

function RefreshButton(props: Props): JSX.Element {
  const shouldThrottle = !props.busy;
  const throttledBusy = useThrottle(props.busy, shouldThrottle ? 600 : 0);

  return (
    <StyledIconButton
      className={props.className}
      onClick={throttledBusy ? undefined : props.onClick}
      aria-disabled={throttledBusy}
      aria-label={props["aria-label"] || "Refresh"}
      data-testid={props["data-testid"]}
    >
      <RefreshIcon />
    </StyledIconButton>
  );
}

export default RefreshButton;
