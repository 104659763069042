import type { ModalTypeProperties } from "../../types";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { EnvironmentAddModal } from "./EnvironmentAddModal";
import { EnvironmentSelectorModal } from "./EnvironmentSelectorModal";

export function ModalContent({
  modal,
}: {
  modal: ModalTypeProperties;
}): JSX.Element {
  switch (modal.type) {
    case "confirmationDialog":
      return <ConfirmationDialog {...modal} />;
    case "environmentAdd":
      return <EnvironmentAddModal {...modal} />;
    case "environmentSelector":
      return <EnvironmentSelectorModal {...modal} />;
  }
}
