import styled, { css } from "styled-components";
import { motion, Variants } from "framer-motion";

import {
  CONTENT_ITEM_BORDER_SIZE,
  CONTENT_PLACEHOLDER_GAP,
  CONTENT_PLACEHOLDER_MARGIN,
} from "./constants";
import { ClippedIconType, useClippedIcon } from "../Icon";

type StudioPageContentPlaceholderProps = {
  children: React.ReactNode;
  icon: ClippedIconType;
};

export function PageContentPlaceholder({
  children,
  icon,
}: StudioPageContentPlaceholderProps) {
  const { ClippedIcon, iconId } = useClippedIcon(icon);

  return (
    <PlaceholderContainer
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
      data-testid="page-placeholder"
    >
      <NoResultsIconWrapper>
        <ClippedIconContainer $iconId={iconId}>
          <ClippedIcon />
        </ClippedIconContainer>
      </NoResultsIconWrapper>
      {children}
    </PlaceholderContainer>
  );
}

const variants: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      delay: 0.3,
    },
  },
  exit: { opacity: 0 },
};

const NoResultsIconWrapper = styled.div`
  height: 96px;
  width: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlaceholderContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin-top: ${CONTENT_ITEM_BORDER_SIZE};
  gap: ${CONTENT_PLACEHOLDER_GAP};
`;

const ClippedIconContainer = styled.div<{ $iconId: string }>(
  ({ $iconId, theme }) => css`
    background: linear-gradient(
      180deg,
      ${theme.colorSecondary600} 0%,
      ${theme.colorSecondary200} 100%
    );
    clip-path: url("#${$iconId}");
    display: flex;
  `
);

export const PageContentPlaceholderText = styled.h3`
  font: ${({ theme }) => theme.fontStudioHeadingsH3ShortHand};
`;

export const PageContentPlaceholderDescription = styled.p(
  ({ theme }) => css`
    font: ${theme.fontStudioBodyCopyRegularShortHand};
    letter-spacing: ${theme.fontStudioBodyCopyRegularLetterSpacing};
    color: ${theme.colorBase600};
    margin: 0;
  `
);

export const PageContentPlaceholderCTA = styled.nav`
  margin-top: calc(${CONTENT_PLACEHOLDER_MARGIN} - ${CONTENT_PLACEHOLDER_GAP});
`;
