// ============================================= //
// Theme file                                    //
//                                               //
// Generated by style-dictionary                 //
// This file is generated. PLEASE DO NOT MODIFY. //
// ============================================= //
import { createGlobalStyle } from "styled-components";

const GlobalStyleVariables = createGlobalStyle`
  :root {
    --colorBackground: ${({ theme }) => theme.colorBackground};
    --colorForeground: ${({ theme }) => theme.colorForeground};
    --colorBase100: ${({ theme }) => theme.colorBase100};
    --colorBase200: ${({ theme }) => theme.colorBase200};
    --colorBase300: ${({ theme }) => theme.colorBase300};
    --colorBase400: ${({ theme }) => theme.colorBase400};
    --colorBase500: ${({ theme }) => theme.colorBase500};
    --colorBase600: ${({ theme }) => theme.colorBase600};
    --colorBase700: ${({ theme }) => theme.colorBase700};
    --colorBase800: ${({ theme }) => theme.colorBase800};
    --colorBaseAlpha100: ${({ theme }) => theme.colorBaseAlpha100};
    --colorBaseAlpha200: ${({ theme }) => theme.colorBaseAlpha200};
    --colorBaseAlpha300: ${({ theme }) => theme.colorBaseAlpha300};
    --colorBaseAlpha400: ${({ theme }) => theme.colorBaseAlpha400};
    --colorPrimary50: ${({ theme }) => theme.colorPrimary50};
    --colorPrimary100: ${({ theme }) => theme.colorPrimary100};
    --colorPrimary200: ${({ theme }) => theme.colorPrimary200};
    --colorPrimary300: ${({ theme }) => theme.colorPrimary300};
    --colorPrimary400: ${({ theme }) => theme.colorPrimary400};
    --colorPrimary500: ${({ theme }) => theme.colorPrimary500};
    --colorPrimary600: ${({ theme }) => theme.colorPrimary600};
    --colorPrimary700: ${({ theme }) => theme.colorPrimary700};
    --colorPrimary800: ${({ theme }) => theme.colorPrimary800};
    --colorPrimaryAlpha50: ${({ theme }) => theme.colorPrimaryAlpha50};
    --colorPrimaryAlpha100: ${({ theme }) => theme.colorPrimaryAlpha100};
    --colorPrimaryAlpha200: ${({ theme }) => theme.colorPrimaryAlpha200};
    --colorPrimaryAlpha300: ${({ theme }) => theme.colorPrimaryAlpha300};
    --colorPrimaryAlpha400: ${({ theme }) => theme.colorPrimaryAlpha400};
    --colorSecondary100: ${({ theme }) => theme.colorSecondary100};
    --colorSecondary200: ${({ theme }) => theme.colorSecondary200};
    --colorSecondary300: ${({ theme }) => theme.colorSecondary300};
    --colorSecondary400: ${({ theme }) => theme.colorSecondary400};
    --colorSecondary500: ${({ theme }) => theme.colorSecondary500};
    --colorSecondary600: ${({ theme }) => theme.colorSecondary600};
    --colorSecondary700: ${({ theme }) => theme.colorSecondary700};
    --colorSecondary800: ${({ theme }) => theme.colorSecondary800};
    --colorSecondarySecondary100: ${({ theme }) => theme.colorSecondarySecondary100};
    --colorSecondarySecondary200: ${({ theme }) => theme.colorSecondarySecondary200};
    --colorSecondarySecondary300: ${({ theme }) => theme.colorSecondarySecondary300};
    --colorSecondarySecondary400: ${({ theme }) => theme.colorSecondarySecondary400};
    --colorSuccess100: ${({ theme }) => theme.colorSuccess100};
    --colorSuccess200: ${({ theme }) => theme.colorSuccess200};
    --colorSuccess300: ${({ theme }) => theme.colorSuccess300};
    --colorSuccess400: ${({ theme }) => theme.colorSuccess400};
    --colorSuccess500: ${({ theme }) => theme.colorSuccess500};
    --colorSuccess600: ${({ theme }) => theme.colorSuccess600};
    --colorSuccess700: ${({ theme }) => theme.colorSuccess700};
    --colorSuccess800: ${({ theme }) => theme.colorSuccess800};
    --colorSuccessAlpha100: ${({ theme }) => theme.colorSuccessAlpha100};
    --colorSuccessAlpha200: ${({ theme }) => theme.colorSuccessAlpha200};
    --colorSuccessAlpha300: ${({ theme }) => theme.colorSuccessAlpha300};
    --colorSuccessAlpha400: ${({ theme }) => theme.colorSuccessAlpha400};
    --colorDanger100: ${({ theme }) => theme.colorDanger100};
    --colorDanger200: ${({ theme }) => theme.colorDanger200};
    --colorDanger300: ${({ theme }) => theme.colorDanger300};
    --colorDanger400: ${({ theme }) => theme.colorDanger400};
    --colorDanger500: ${({ theme }) => theme.colorDanger500};
    --colorDanger600: ${({ theme }) => theme.colorDanger600};
    --colorDanger700: ${({ theme }) => theme.colorDanger700};
    --colorDanger800: ${({ theme }) => theme.colorDanger800};
    --colorDangerAlpha100: ${({ theme }) => theme.colorDangerAlpha100};
    --colorDangerAlpha200: ${({ theme }) => theme.colorDangerAlpha200};
    --colorDangerAlpha300: ${({ theme }) => theme.colorDangerAlpha300};
    --colorDangerAlpha400: ${({ theme }) => theme.colorDangerAlpha400};
    --colorWarning100: ${({ theme }) => theme.colorWarning100};
    --colorWarning200: ${({ theme }) => theme.colorWarning200};
    --colorWarning300: ${({ theme }) => theme.colorWarning300};
    --colorWarning400: ${({ theme }) => theme.colorWarning400};
    --colorWarning500: ${({ theme }) => theme.colorWarning500};
    --colorWarning600: ${({ theme }) => theme.colorWarning600};
    --colorWarning700: ${({ theme }) => theme.colorWarning700};
    --colorWarning800: ${({ theme }) => theme.colorWarning800};
    --colorWarningAlpha100: ${({ theme }) => theme.colorWarningAlpha100};
    --colorWarningAlpha200: ${({ theme }) => theme.colorWarningAlpha200};
    --colorWarningAlpha300: ${({ theme }) => theme.colorWarningAlpha300};
    --colorWarningAlpha400: ${({ theme }) => theme.colorWarningAlpha400};
    --colorSupport1100: ${({ theme }) => theme.colorSupport1100};
    --colorSupport1200: ${({ theme }) => theme.colorSupport1200};
    --colorSupport1300: ${({ theme }) => theme.colorSupport1300};
    --colorSupport1400: ${({ theme }) => theme.colorSupport1400};
    --colorSupport2100: ${({ theme }) => theme.colorSupport2100};
    --colorSupport2200: ${({ theme }) => theme.colorSupport2200};
    --colorSupport2300: ${({ theme }) => theme.colorSupport2300};
    --colorSupport2400: ${({ theme }) => theme.colorSupport2400};
    --colorSupport3100: ${({ theme }) => theme.colorSupport3100};
    --colorSupport3200: ${({ theme }) => theme.colorSupport3200};
    --colorSupport3300: ${({ theme }) => theme.colorSupport3300};
    --colorSupport3400: ${({ theme }) => theme.colorSupport3400};
    --colorSupport4100: ${({ theme }) => theme.colorSupport4100};
    --colorSupport4200: ${({ theme }) => theme.colorSupport4200};
    --colorSupport4300: ${({ theme }) => theme.colorSupport4300};
    --colorSupport4400: ${({ theme }) => theme.colorSupport4400};
    --colorSupport5100: ${({ theme }) => theme.colorSupport5100};
    --colorSupport5200: ${({ theme }) => theme.colorSupport5200};
    --colorSupport5300: ${({ theme }) => theme.colorSupport5300};
    --colorSupport5400: ${({ theme }) => theme.colorSupport5400};
    --colorSupport6100: ${({ theme }) => theme.colorSupport6100};
    --colorSupport6200: ${({ theme }) => theme.colorSupport6200};
    --colorSupport6300: ${({ theme }) => theme.colorSupport6300};
    --colorSupport6400: ${({ theme }) => theme.colorSupport6400};
    --colorSupport7100: ${({ theme }) => theme.colorSupport7100};
    --colorSupport7200: ${({ theme }) => theme.colorSupport7200};
    --colorSupport7300: ${({ theme }) => theme.colorSupport7300};
    --colorSupport7400: ${({ theme }) => theme.colorSupport7400};
    --colorSupport8100: ${({ theme }) => theme.colorSupport8100};
    --colorSupport8200: ${({ theme }) => theme.colorSupport8200};
    --colorSupport8300: ${({ theme }) => theme.colorSupport8300};
    --colorSupport8400: ${({ theme }) => theme.colorSupport8400};
    --colorSupport9100: ${({ theme }) => theme.colorSupport9100};
    --colorSupport9200: ${({ theme }) => theme.colorSupport9200};
    --colorSupport9300: ${({ theme }) => theme.colorSupport9300};
    --colorSupport9400: ${({ theme }) => theme.colorSupport9400};
    --colorSupport10100: ${({ theme }) => theme.colorSupport10100};
    --colorSupport10200: ${({ theme }) => theme.colorSupport10200};
    --colorSupport10300: ${({ theme }) => theme.colorSupport10300};
    --colorSupport10400: ${({ theme }) => theme.colorSupport10400};
    --colorSupport11100: ${({ theme }) => theme.colorSupport11100};
    --colorSupport11200: ${({ theme }) => theme.colorSupport11200};
    --colorSupport11300: ${({ theme }) => theme.colorSupport11300};
    --colorSupport11400: ${({ theme }) => theme.colorSupport11400};
    --colorViews1100: ${({ theme }) => theme.colorViews1100};
    --colorViews1200: ${({ theme }) => theme.colorViews1200};
    --colorViews2100: ${({ theme }) => theme.colorViews2100};
    --colorViews2200: ${({ theme }) => theme.colorViews2200};
    --colorViews3100: ${({ theme }) => theme.colorViews3100};
    --colorViews3200: ${({ theme }) => theme.colorViews3200};
    --colorViews4100: ${({ theme }) => theme.colorViews4100};
    --colorViews4200: ${({ theme }) => theme.colorViews4200};
    --colorViews5100: ${({ theme }) => theme.colorViews5100};
    --colorViews5200: ${({ theme }) => theme.colorViews5200};
    --colorViews6100: ${({ theme }) => theme.colorViews6100};
    --colorViews6200: ${({ theme }) => theme.colorViews6200};
    --colorViews7100: ${({ theme }) => theme.colorViews7100};
    --colorViews7200: ${({ theme }) => theme.colorViews7200};
    --colorViews8100: ${({ theme }) => theme.colorViews8100};
    --colorViews8200: ${({ theme }) => theme.colorViews8200};
    --colorViews9100: ${({ theme }) => theme.colorViews9100};
    --colorViews9200: ${({ theme }) => theme.colorViews9200};
    --fontNotebooksBaseFontSize: ${({ theme }) => theme.fontNotebooksBaseFontSize};
    --fontNotebooksBaseTextDecoration: ${({ theme }) => theme.fontNotebooksBaseTextDecoration};
    --fontNotebooksBaseFontFamily: ${({ theme }) => theme.fontNotebooksBaseFontFamily};
    --fontNotebooksBaseFontWeight: ${({ theme }) => theme.fontNotebooksBaseFontWeight};
    --fontNotebooksBaseFontStyle: ${({ theme }) => theme.fontNotebooksBaseFontStyle};
    --fontNotebooksBaseFontStretch: ${({ theme }) => theme.fontNotebooksBaseFontStretch};
    --fontNotebooksBaseFontStyleOld: ${({ theme }) => theme.fontNotebooksBaseFontStyleOld};
    --fontNotebooksBaseLetterSpacing: ${({ theme }) => theme.fontNotebooksBaseLetterSpacing};
    --fontNotebooksBaseLineHeight: ${({ theme }) => theme.fontNotebooksBaseLineHeight};
    --fontNotebooksBaseParagraphIndent: ${({ theme }) => theme.fontNotebooksBaseParagraphIndent};
    --fontNotebooksBaseParagraphSpacing: ${({ theme }) => theme.fontNotebooksBaseParagraphSpacing};
    --fontNotebooksBaseTextCase: ${({ theme }) => theme.fontNotebooksBaseTextCase};
    --fontNotebooksBaseShortHand: ${({ theme }) => theme.fontNotebooksBaseShortHand};
    --fontNotebooksH1FontSize: ${({ theme }) => theme.fontNotebooksH1FontSize};
    --fontNotebooksH1TextDecoration: ${({ theme }) => theme.fontNotebooksH1TextDecoration};
    --fontNotebooksH1FontFamily: ${({ theme }) => theme.fontNotebooksH1FontFamily};
    --fontNotebooksH1FontWeight: ${({ theme }) => theme.fontNotebooksH1FontWeight};
    --fontNotebooksH1FontStyle: ${({ theme }) => theme.fontNotebooksH1FontStyle};
    --fontNotebooksH1FontStretch: ${({ theme }) => theme.fontNotebooksH1FontStretch};
    --fontNotebooksH1FontStyleOld: ${({ theme }) => theme.fontNotebooksH1FontStyleOld};
    --fontNotebooksH1LetterSpacing: ${({ theme }) => theme.fontNotebooksH1LetterSpacing};
    --fontNotebooksH1LineHeight: ${({ theme }) => theme.fontNotebooksH1LineHeight};
    --fontNotebooksH1ParagraphIndent: ${({ theme }) => theme.fontNotebooksH1ParagraphIndent};
    --fontNotebooksH1ParagraphSpacing: ${({ theme }) => theme.fontNotebooksH1ParagraphSpacing};
    --fontNotebooksH1TextCase: ${({ theme }) => theme.fontNotebooksH1TextCase};
    --fontNotebooksH1ShortHand: ${({ theme }) => theme.fontNotebooksH1ShortHand};
    --fontNotebooksH2FontSize: ${({ theme }) => theme.fontNotebooksH2FontSize};
    --fontNotebooksH2TextDecoration: ${({ theme }) => theme.fontNotebooksH2TextDecoration};
    --fontNotebooksH2FontFamily: ${({ theme }) => theme.fontNotebooksH2FontFamily};
    --fontNotebooksH2FontWeight: ${({ theme }) => theme.fontNotebooksH2FontWeight};
    --fontNotebooksH2FontStyle: ${({ theme }) => theme.fontNotebooksH2FontStyle};
    --fontNotebooksH2FontStretch: ${({ theme }) => theme.fontNotebooksH2FontStretch};
    --fontNotebooksH2FontStyleOld: ${({ theme }) => theme.fontNotebooksH2FontStyleOld};
    --fontNotebooksH2LetterSpacing: ${({ theme }) => theme.fontNotebooksH2LetterSpacing};
    --fontNotebooksH2LineHeight: ${({ theme }) => theme.fontNotebooksH2LineHeight};
    --fontNotebooksH2ParagraphIndent: ${({ theme }) => theme.fontNotebooksH2ParagraphIndent};
    --fontNotebooksH2ParagraphSpacing: ${({ theme }) => theme.fontNotebooksH2ParagraphSpacing};
    --fontNotebooksH2TextCase: ${({ theme }) => theme.fontNotebooksH2TextCase};
    --fontNotebooksH2ShortHand: ${({ theme }) => theme.fontNotebooksH2ShortHand};
    --fontNotebooksH3FontSize: ${({ theme }) => theme.fontNotebooksH3FontSize};
    --fontNotebooksH3TextDecoration: ${({ theme }) => theme.fontNotebooksH3TextDecoration};
    --fontNotebooksH3FontFamily: ${({ theme }) => theme.fontNotebooksH3FontFamily};
    --fontNotebooksH3FontWeight: ${({ theme }) => theme.fontNotebooksH3FontWeight};
    --fontNotebooksH3FontStyle: ${({ theme }) => theme.fontNotebooksH3FontStyle};
    --fontNotebooksH3FontStretch: ${({ theme }) => theme.fontNotebooksH3FontStretch};
    --fontNotebooksH3FontStyleOld: ${({ theme }) => theme.fontNotebooksH3FontStyleOld};
    --fontNotebooksH3LetterSpacing: ${({ theme }) => theme.fontNotebooksH3LetterSpacing};
    --fontNotebooksH3LineHeight: ${({ theme }) => theme.fontNotebooksH3LineHeight};
    --fontNotebooksH3ParagraphIndent: ${({ theme }) => theme.fontNotebooksH3ParagraphIndent};
    --fontNotebooksH3ParagraphSpacing: ${({ theme }) => theme.fontNotebooksH3ParagraphSpacing};
    --fontNotebooksH3TextCase: ${({ theme }) => theme.fontNotebooksH3TextCase};
    --fontNotebooksH3ShortHand: ${({ theme }) => theme.fontNotebooksH3ShortHand};
    --fontNotebooksSmallFontSize: ${({ theme }) => theme.fontNotebooksSmallFontSize};
    --fontNotebooksSmallTextDecoration: ${({ theme }) => theme.fontNotebooksSmallTextDecoration};
    --fontNotebooksSmallFontFamily: ${({ theme }) => theme.fontNotebooksSmallFontFamily};
    --fontNotebooksSmallFontWeight: ${({ theme }) => theme.fontNotebooksSmallFontWeight};
    --fontNotebooksSmallFontStyle: ${({ theme }) => theme.fontNotebooksSmallFontStyle};
    --fontNotebooksSmallFontStretch: ${({ theme }) => theme.fontNotebooksSmallFontStretch};
    --fontNotebooksSmallFontStyleOld: ${({ theme }) => theme.fontNotebooksSmallFontStyleOld};
    --fontNotebooksSmallLetterSpacing: ${({ theme }) => theme.fontNotebooksSmallLetterSpacing};
    --fontNotebooksSmallLineHeight: ${({ theme }) => theme.fontNotebooksSmallLineHeight};
    --fontNotebooksSmallParagraphIndent: ${({ theme }) => theme.fontNotebooksSmallParagraphIndent};
    --fontNotebooksSmallParagraphSpacing: ${({ theme }) => theme.fontNotebooksSmallParagraphSpacing};
    --fontNotebooksSmallTextCase: ${({ theme }) => theme.fontNotebooksSmallTextCase};
    --fontNotebooksSmallShortHand: ${({ theme }) => theme.fontNotebooksSmallShortHand};
    --fontNotebooksSmallerFontSize: ${({ theme }) => theme.fontNotebooksSmallerFontSize};
    --fontNotebooksSmallerTextDecoration: ${({ theme }) => theme.fontNotebooksSmallerTextDecoration};
    --fontNotebooksSmallerFontFamily: ${({ theme }) => theme.fontNotebooksSmallerFontFamily};
    --fontNotebooksSmallerFontWeight: ${({ theme }) => theme.fontNotebooksSmallerFontWeight};
    --fontNotebooksSmallerFontStyle: ${({ theme }) => theme.fontNotebooksSmallerFontStyle};
    --fontNotebooksSmallerFontStretch: ${({ theme }) => theme.fontNotebooksSmallerFontStretch};
    --fontNotebooksSmallerFontStyleOld: ${({ theme }) => theme.fontNotebooksSmallerFontStyleOld};
    --fontNotebooksSmallerLetterSpacing: ${({ theme }) => theme.fontNotebooksSmallerLetterSpacing};
    --fontNotebooksSmallerLineHeight: ${({ theme }) => theme.fontNotebooksSmallerLineHeight};
    --fontNotebooksSmallerParagraphIndent: ${({ theme }) => theme.fontNotebooksSmallerParagraphIndent};
    --fontNotebooksSmallerParagraphSpacing: ${({ theme }) => theme.fontNotebooksSmallerParagraphSpacing};
    --fontNotebooksSmallerTextCase: ${({ theme }) => theme.fontNotebooksSmallerTextCase};
    --fontNotebooksSmallerShortHand: ${({ theme }) => theme.fontNotebooksSmallerShortHand};
    --fontNotebooksSmallestFontSize: ${({ theme }) => theme.fontNotebooksSmallestFontSize};
    --fontNotebooksSmallestTextDecoration: ${({ theme }) => theme.fontNotebooksSmallestTextDecoration};
    --fontNotebooksSmallestFontFamily: ${({ theme }) => theme.fontNotebooksSmallestFontFamily};
    --fontNotebooksSmallestFontWeight: ${({ theme }) => theme.fontNotebooksSmallestFontWeight};
    --fontNotebooksSmallestFontStyle: ${({ theme }) => theme.fontNotebooksSmallestFontStyle};
    --fontNotebooksSmallestFontStretch: ${({ theme }) => theme.fontNotebooksSmallestFontStretch};
    --fontNotebooksSmallestFontStyleOld: ${({ theme }) => theme.fontNotebooksSmallestFontStyleOld};
    --fontNotebooksSmallestLetterSpacing: ${({ theme }) => theme.fontNotebooksSmallestLetterSpacing};
    --fontNotebooksSmallestLineHeight: ${({ theme }) => theme.fontNotebooksSmallestLineHeight};
    --fontNotebooksSmallestParagraphIndent: ${({ theme }) => theme.fontNotebooksSmallestParagraphIndent};
    --fontNotebooksSmallestParagraphSpacing: ${({ theme }) => theme.fontNotebooksSmallestParagraphSpacing};
    --fontNotebooksSmallestTextCase: ${({ theme }) => theme.fontNotebooksSmallestTextCase};
    --fontNotebooksSmallestShortHand: ${({ theme }) => theme.fontNotebooksSmallestShortHand};
    --fontNotebooksCodeFontSize: ${({ theme }) => theme.fontNotebooksCodeFontSize};
    --fontNotebooksCodeTextDecoration: ${({ theme }) => theme.fontNotebooksCodeTextDecoration};
    --fontNotebooksCodeFontFamily: ${({ theme }) => theme.fontNotebooksCodeFontFamily};
    --fontNotebooksCodeFontWeight: ${({ theme }) => theme.fontNotebooksCodeFontWeight};
    --fontNotebooksCodeFontStyle: ${({ theme }) => theme.fontNotebooksCodeFontStyle};
    --fontNotebooksCodeFontStretch: ${({ theme }) => theme.fontNotebooksCodeFontStretch};
    --fontNotebooksCodeFontStyleOld: ${({ theme }) => theme.fontNotebooksCodeFontStyleOld};
    --fontNotebooksCodeLetterSpacing: ${({ theme }) => theme.fontNotebooksCodeLetterSpacing};
    --fontNotebooksCodeLineHeight: ${({ theme }) => theme.fontNotebooksCodeLineHeight};
    --fontNotebooksCodeParagraphIndent: ${({ theme }) => theme.fontNotebooksCodeParagraphIndent};
    --fontNotebooksCodeParagraphSpacing: ${({ theme }) => theme.fontNotebooksCodeParagraphSpacing};
    --fontNotebooksCodeTextCase: ${({ theme }) => theme.fontNotebooksCodeTextCase};
    --fontNotebooksCodeShortHand: ${({ theme }) => theme.fontNotebooksCodeShortHand};
    --fontStudioHeadingsH1FontSize: ${({ theme }) => theme.fontStudioHeadingsH1FontSize};
    --fontStudioHeadingsH1TextDecoration: ${({ theme }) => theme.fontStudioHeadingsH1TextDecoration};
    --fontStudioHeadingsH1FontFamily: ${({ theme }) => theme.fontStudioHeadingsH1FontFamily};
    --fontStudioHeadingsH1FontWeight: ${({ theme }) => theme.fontStudioHeadingsH1FontWeight};
    --fontStudioHeadingsH1FontStyle: ${({ theme }) => theme.fontStudioHeadingsH1FontStyle};
    --fontStudioHeadingsH1FontStretch: ${({ theme }) => theme.fontStudioHeadingsH1FontStretch};
    --fontStudioHeadingsH1FontStyleOld: ${({ theme }) => theme.fontStudioHeadingsH1FontStyleOld};
    --fontStudioHeadingsH1LetterSpacing: ${({ theme }) => theme.fontStudioHeadingsH1LetterSpacing};
    --fontStudioHeadingsH1LineHeight: ${({ theme }) => theme.fontStudioHeadingsH1LineHeight};
    --fontStudioHeadingsH1ParagraphIndent: ${({ theme }) => theme.fontStudioHeadingsH1ParagraphIndent};
    --fontStudioHeadingsH1ParagraphSpacing: ${({ theme }) => theme.fontStudioHeadingsH1ParagraphSpacing};
    --fontStudioHeadingsH1TextCase: ${({ theme }) => theme.fontStudioHeadingsH1TextCase};
    --fontStudioHeadingsH1ShortHand: ${({ theme }) => theme.fontStudioHeadingsH1ShortHand};
    --fontStudioHeadingsH2FontSize: ${({ theme }) => theme.fontStudioHeadingsH2FontSize};
    --fontStudioHeadingsH2TextDecoration: ${({ theme }) => theme.fontStudioHeadingsH2TextDecoration};
    --fontStudioHeadingsH2FontFamily: ${({ theme }) => theme.fontStudioHeadingsH2FontFamily};
    --fontStudioHeadingsH2FontWeight: ${({ theme }) => theme.fontStudioHeadingsH2FontWeight};
    --fontStudioHeadingsH2FontStyle: ${({ theme }) => theme.fontStudioHeadingsH2FontStyle};
    --fontStudioHeadingsH2FontStretch: ${({ theme }) => theme.fontStudioHeadingsH2FontStretch};
    --fontStudioHeadingsH2FontStyleOld: ${({ theme }) => theme.fontStudioHeadingsH2FontStyleOld};
    --fontStudioHeadingsH2LetterSpacing: ${({ theme }) => theme.fontStudioHeadingsH2LetterSpacing};
    --fontStudioHeadingsH2LineHeight: ${({ theme }) => theme.fontStudioHeadingsH2LineHeight};
    --fontStudioHeadingsH2ParagraphIndent: ${({ theme }) => theme.fontStudioHeadingsH2ParagraphIndent};
    --fontStudioHeadingsH2ParagraphSpacing: ${({ theme }) => theme.fontStudioHeadingsH2ParagraphSpacing};
    --fontStudioHeadingsH2TextCase: ${({ theme }) => theme.fontStudioHeadingsH2TextCase};
    --fontStudioHeadingsH2ShortHand: ${({ theme }) => theme.fontStudioHeadingsH2ShortHand};
    --fontStudioHeadingsH3FontSize: ${({ theme }) => theme.fontStudioHeadingsH3FontSize};
    --fontStudioHeadingsH3TextDecoration: ${({ theme }) => theme.fontStudioHeadingsH3TextDecoration};
    --fontStudioHeadingsH3FontFamily: ${({ theme }) => theme.fontStudioHeadingsH3FontFamily};
    --fontStudioHeadingsH3FontWeight: ${({ theme }) => theme.fontStudioHeadingsH3FontWeight};
    --fontStudioHeadingsH3FontStyle: ${({ theme }) => theme.fontStudioHeadingsH3FontStyle};
    --fontStudioHeadingsH3FontStretch: ${({ theme }) => theme.fontStudioHeadingsH3FontStretch};
    --fontStudioHeadingsH3FontStyleOld: ${({ theme }) => theme.fontStudioHeadingsH3FontStyleOld};
    --fontStudioHeadingsH3LetterSpacing: ${({ theme }) => theme.fontStudioHeadingsH3LetterSpacing};
    --fontStudioHeadingsH3LineHeight: ${({ theme }) => theme.fontStudioHeadingsH3LineHeight};
    --fontStudioHeadingsH3ParagraphIndent: ${({ theme }) => theme.fontStudioHeadingsH3ParagraphIndent};
    --fontStudioHeadingsH3ParagraphSpacing: ${({ theme }) => theme.fontStudioHeadingsH3ParagraphSpacing};
    --fontStudioHeadingsH3TextCase: ${({ theme }) => theme.fontStudioHeadingsH3TextCase};
    --fontStudioHeadingsH3ShortHand: ${({ theme }) => theme.fontStudioHeadingsH3ShortHand};
    --fontStudioHeadingsH4FontSize: ${({ theme }) => theme.fontStudioHeadingsH4FontSize};
    --fontStudioHeadingsH4TextDecoration: ${({ theme }) => theme.fontStudioHeadingsH4TextDecoration};
    --fontStudioHeadingsH4FontFamily: ${({ theme }) => theme.fontStudioHeadingsH4FontFamily};
    --fontStudioHeadingsH4FontWeight: ${({ theme }) => theme.fontStudioHeadingsH4FontWeight};
    --fontStudioHeadingsH4FontStyle: ${({ theme }) => theme.fontStudioHeadingsH4FontStyle};
    --fontStudioHeadingsH4FontStretch: ${({ theme }) => theme.fontStudioHeadingsH4FontStretch};
    --fontStudioHeadingsH4FontStyleOld: ${({ theme }) => theme.fontStudioHeadingsH4FontStyleOld};
    --fontStudioHeadingsH4LetterSpacing: ${({ theme }) => theme.fontStudioHeadingsH4LetterSpacing};
    --fontStudioHeadingsH4LineHeight: ${({ theme }) => theme.fontStudioHeadingsH4LineHeight};
    --fontStudioHeadingsH4ParagraphIndent: ${({ theme }) => theme.fontStudioHeadingsH4ParagraphIndent};
    --fontStudioHeadingsH4ParagraphSpacing: ${({ theme }) => theme.fontStudioHeadingsH4ParagraphSpacing};
    --fontStudioHeadingsH4TextCase: ${({ theme }) => theme.fontStudioHeadingsH4TextCase};
    --fontStudioHeadingsH4ShortHand: ${({ theme }) => theme.fontStudioHeadingsH4ShortHand};
    --fontStudioHeadingsH5FontSize: ${({ theme }) => theme.fontStudioHeadingsH5FontSize};
    --fontStudioHeadingsH5TextDecoration: ${({ theme }) => theme.fontStudioHeadingsH5TextDecoration};
    --fontStudioHeadingsH5FontFamily: ${({ theme }) => theme.fontStudioHeadingsH5FontFamily};
    --fontStudioHeadingsH5FontWeight: ${({ theme }) => theme.fontStudioHeadingsH5FontWeight};
    --fontStudioHeadingsH5FontStyle: ${({ theme }) => theme.fontStudioHeadingsH5FontStyle};
    --fontStudioHeadingsH5FontStretch: ${({ theme }) => theme.fontStudioHeadingsH5FontStretch};
    --fontStudioHeadingsH5FontStyleOld: ${({ theme }) => theme.fontStudioHeadingsH5FontStyleOld};
    --fontStudioHeadingsH5LetterSpacing: ${({ theme }) => theme.fontStudioHeadingsH5LetterSpacing};
    --fontStudioHeadingsH5LineHeight: ${({ theme }) => theme.fontStudioHeadingsH5LineHeight};
    --fontStudioHeadingsH5ParagraphIndent: ${({ theme }) => theme.fontStudioHeadingsH5ParagraphIndent};
    --fontStudioHeadingsH5ParagraphSpacing: ${({ theme }) => theme.fontStudioHeadingsH5ParagraphSpacing};
    --fontStudioHeadingsH5TextCase: ${({ theme }) => theme.fontStudioHeadingsH5TextCase};
    --fontStudioHeadingsH5ShortHand: ${({ theme }) => theme.fontStudioHeadingsH5ShortHand};
    --fontStudioHeadingsH6FontSize: ${({ theme }) => theme.fontStudioHeadingsH6FontSize};
    --fontStudioHeadingsH6TextDecoration: ${({ theme }) => theme.fontStudioHeadingsH6TextDecoration};
    --fontStudioHeadingsH6FontFamily: ${({ theme }) => theme.fontStudioHeadingsH6FontFamily};
    --fontStudioHeadingsH6FontWeight: ${({ theme }) => theme.fontStudioHeadingsH6FontWeight};
    --fontStudioHeadingsH6FontStyle: ${({ theme }) => theme.fontStudioHeadingsH6FontStyle};
    --fontStudioHeadingsH6FontStretch: ${({ theme }) => theme.fontStudioHeadingsH6FontStretch};
    --fontStudioHeadingsH6FontStyleOld: ${({ theme }) => theme.fontStudioHeadingsH6FontStyleOld};
    --fontStudioHeadingsH6LetterSpacing: ${({ theme }) => theme.fontStudioHeadingsH6LetterSpacing};
    --fontStudioHeadingsH6LineHeight: ${({ theme }) => theme.fontStudioHeadingsH6LineHeight};
    --fontStudioHeadingsH6ParagraphIndent: ${({ theme }) => theme.fontStudioHeadingsH6ParagraphIndent};
    --fontStudioHeadingsH6ParagraphSpacing: ${({ theme }) => theme.fontStudioHeadingsH6ParagraphSpacing};
    --fontStudioHeadingsH6TextCase: ${({ theme }) => theme.fontStudioHeadingsH6TextCase};
    --fontStudioHeadingsH6ShortHand: ${({ theme }) => theme.fontStudioHeadingsH6ShortHand};
    --fontStudioBodyCopyLargeFontSize: ${({ theme }) => theme.fontStudioBodyCopyLargeFontSize};
    --fontStudioBodyCopyLargeTextDecoration: ${({ theme }) => theme.fontStudioBodyCopyLargeTextDecoration};
    --fontStudioBodyCopyLargeFontFamily: ${({ theme }) => theme.fontStudioBodyCopyLargeFontFamily};
    --fontStudioBodyCopyLargeFontWeight: ${({ theme }) => theme.fontStudioBodyCopyLargeFontWeight};
    --fontStudioBodyCopyLargeFontStyle: ${({ theme }) => theme.fontStudioBodyCopyLargeFontStyle};
    --fontStudioBodyCopyLargeFontStretch: ${({ theme }) => theme.fontStudioBodyCopyLargeFontStretch};
    --fontStudioBodyCopyLargeFontStyleOld: ${({ theme }) => theme.fontStudioBodyCopyLargeFontStyleOld};
    --fontStudioBodyCopyLargeLetterSpacing: ${({ theme }) => theme.fontStudioBodyCopyLargeLetterSpacing};
    --fontStudioBodyCopyLargeLineHeight: ${({ theme }) => theme.fontStudioBodyCopyLargeLineHeight};
    --fontStudioBodyCopyLargeParagraphIndent: ${({ theme }) => theme.fontStudioBodyCopyLargeParagraphIndent};
    --fontStudioBodyCopyLargeParagraphSpacing: ${({ theme }) => theme.fontStudioBodyCopyLargeParagraphSpacing};
    --fontStudioBodyCopyLargeTextCase: ${({ theme }) => theme.fontStudioBodyCopyLargeTextCase};
    --fontStudioBodyCopyLargeShortHand: ${({ theme }) => theme.fontStudioBodyCopyLargeShortHand};
    --fontStudioBodyCopyRegularFontSize: ${({ theme }) => theme.fontStudioBodyCopyRegularFontSize};
    --fontStudioBodyCopyRegularTextDecoration: ${({ theme }) => theme.fontStudioBodyCopyRegularTextDecoration};
    --fontStudioBodyCopyRegularFontFamily: ${({ theme }) => theme.fontStudioBodyCopyRegularFontFamily};
    --fontStudioBodyCopyRegularFontWeight: ${({ theme }) => theme.fontStudioBodyCopyRegularFontWeight};
    --fontStudioBodyCopyRegularFontStyle: ${({ theme }) => theme.fontStudioBodyCopyRegularFontStyle};
    --fontStudioBodyCopyRegularFontStretch: ${({ theme }) => theme.fontStudioBodyCopyRegularFontStretch};
    --fontStudioBodyCopyRegularFontStyleOld: ${({ theme }) => theme.fontStudioBodyCopyRegularFontStyleOld};
    --fontStudioBodyCopyRegularLetterSpacing: ${({ theme }) => theme.fontStudioBodyCopyRegularLetterSpacing};
    --fontStudioBodyCopyRegularLineHeight: ${({ theme }) => theme.fontStudioBodyCopyRegularLineHeight};
    --fontStudioBodyCopyRegularParagraphIndent: ${({ theme }) => theme.fontStudioBodyCopyRegularParagraphIndent};
    --fontStudioBodyCopyRegularParagraphSpacing: ${({ theme }) => theme.fontStudioBodyCopyRegularParagraphSpacing};
    --fontStudioBodyCopyRegularTextCase: ${({ theme }) => theme.fontStudioBodyCopyRegularTextCase};
    --fontStudioBodyCopyRegularShortHand: ${({ theme }) => theme.fontStudioBodyCopyRegularShortHand};
    --fontStudioBodyCopySmallFontSize: ${({ theme }) => theme.fontStudioBodyCopySmallFontSize};
    --fontStudioBodyCopySmallTextDecoration: ${({ theme }) => theme.fontStudioBodyCopySmallTextDecoration};
    --fontStudioBodyCopySmallFontFamily: ${({ theme }) => theme.fontStudioBodyCopySmallFontFamily};
    --fontStudioBodyCopySmallFontWeight: ${({ theme }) => theme.fontStudioBodyCopySmallFontWeight};
    --fontStudioBodyCopySmallFontStyle: ${({ theme }) => theme.fontStudioBodyCopySmallFontStyle};
    --fontStudioBodyCopySmallFontStretch: ${({ theme }) => theme.fontStudioBodyCopySmallFontStretch};
    --fontStudioBodyCopySmallFontStyleOld: ${({ theme }) => theme.fontStudioBodyCopySmallFontStyleOld};
    --fontStudioBodyCopySmallLetterSpacing: ${({ theme }) => theme.fontStudioBodyCopySmallLetterSpacing};
    --fontStudioBodyCopySmallLineHeight: ${({ theme }) => theme.fontStudioBodyCopySmallLineHeight};
    --fontStudioBodyCopySmallParagraphIndent: ${({ theme }) => theme.fontStudioBodyCopySmallParagraphIndent};
    --fontStudioBodyCopySmallParagraphSpacing: ${({ theme }) => theme.fontStudioBodyCopySmallParagraphSpacing};
    --fontStudioBodyCopySmallTextCase: ${({ theme }) => theme.fontStudioBodyCopySmallTextCase};
    --fontStudioBodyCopySmallShortHand: ${({ theme }) => theme.fontStudioBodyCopySmallShortHand};
    --fontStudioBodyCopyExtraSmallFontSize: ${({ theme }) => theme.fontStudioBodyCopyExtraSmallFontSize};
    --fontStudioBodyCopyExtraSmallTextDecoration: ${({ theme }) => theme.fontStudioBodyCopyExtraSmallTextDecoration};
    --fontStudioBodyCopyExtraSmallFontFamily: ${({ theme }) => theme.fontStudioBodyCopyExtraSmallFontFamily};
    --fontStudioBodyCopyExtraSmallFontWeight: ${({ theme }) => theme.fontStudioBodyCopyExtraSmallFontWeight};
    --fontStudioBodyCopyExtraSmallFontStyle: ${({ theme }) => theme.fontStudioBodyCopyExtraSmallFontStyle};
    --fontStudioBodyCopyExtraSmallFontStretch: ${({ theme }) => theme.fontStudioBodyCopyExtraSmallFontStretch};
    --fontStudioBodyCopyExtraSmallFontStyleOld: ${({ theme }) => theme.fontStudioBodyCopyExtraSmallFontStyleOld};
    --fontStudioBodyCopyExtraSmallLetterSpacing: ${({ theme }) => theme.fontStudioBodyCopyExtraSmallLetterSpacing};
    --fontStudioBodyCopyExtraSmallLineHeight: ${({ theme }) => theme.fontStudioBodyCopyExtraSmallLineHeight};
    --fontStudioBodyCopyExtraSmallParagraphIndent: ${({ theme }) => theme.fontStudioBodyCopyExtraSmallParagraphIndent};
    --fontStudioBodyCopyExtraSmallParagraphSpacing: ${({ theme }) => theme.fontStudioBodyCopyExtraSmallParagraphSpacing};
    --fontStudioBodyCopyExtraSmallTextCase: ${({ theme }) => theme.fontStudioBodyCopyExtraSmallTextCase};
    --fontStudioBodyCopyExtraSmallShortHand: ${({ theme }) => theme.fontStudioBodyCopyExtraSmallShortHand};
    --fontStudioButtonsButtonCopyRegularFontSize: ${({ theme }) => theme.fontStudioButtonsButtonCopyRegularFontSize};
    --fontStudioButtonsButtonCopyRegularTextDecoration: ${({ theme }) => theme.fontStudioButtonsButtonCopyRegularTextDecoration};
    --fontStudioButtonsButtonCopyRegularFontFamily: ${({ theme }) => theme.fontStudioButtonsButtonCopyRegularFontFamily};
    --fontStudioButtonsButtonCopyRegularFontWeight: ${({ theme }) => theme.fontStudioButtonsButtonCopyRegularFontWeight};
    --fontStudioButtonsButtonCopyRegularFontStyle: ${({ theme }) => theme.fontStudioButtonsButtonCopyRegularFontStyle};
    --fontStudioButtonsButtonCopyRegularFontStretch: ${({ theme }) => theme.fontStudioButtonsButtonCopyRegularFontStretch};
    --fontStudioButtonsButtonCopyRegularFontStyleOld: ${({ theme }) => theme.fontStudioButtonsButtonCopyRegularFontStyleOld};
    --fontStudioButtonsButtonCopyRegularLetterSpacing: ${({ theme }) => theme.fontStudioButtonsButtonCopyRegularLetterSpacing};
    --fontStudioButtonsButtonCopyRegularLineHeight: ${({ theme }) => theme.fontStudioButtonsButtonCopyRegularLineHeight};
    --fontStudioButtonsButtonCopyRegularParagraphIndent: ${({ theme }) => theme.fontStudioButtonsButtonCopyRegularParagraphIndent};
    --fontStudioButtonsButtonCopyRegularParagraphSpacing: ${({ theme }) => theme.fontStudioButtonsButtonCopyRegularParagraphSpacing};
    --fontStudioButtonsButtonCopyRegularTextCase: ${({ theme }) => theme.fontStudioButtonsButtonCopyRegularTextCase};
    --fontStudioButtonsButtonCopyRegularShortHand: ${({ theme }) => theme.fontStudioButtonsButtonCopyRegularShortHand};
    --fontStudioButtonsButtonCopySmallFontSize: ${({ theme }) => theme.fontStudioButtonsButtonCopySmallFontSize};
    --fontStudioButtonsButtonCopySmallTextDecoration: ${({ theme }) => theme.fontStudioButtonsButtonCopySmallTextDecoration};
    --fontStudioButtonsButtonCopySmallFontFamily: ${({ theme }) => theme.fontStudioButtonsButtonCopySmallFontFamily};
    --fontStudioButtonsButtonCopySmallFontWeight: ${({ theme }) => theme.fontStudioButtonsButtonCopySmallFontWeight};
    --fontStudioButtonsButtonCopySmallFontStyle: ${({ theme }) => theme.fontStudioButtonsButtonCopySmallFontStyle};
    --fontStudioButtonsButtonCopySmallFontStretch: ${({ theme }) => theme.fontStudioButtonsButtonCopySmallFontStretch};
    --fontStudioButtonsButtonCopySmallFontStyleOld: ${({ theme }) => theme.fontStudioButtonsButtonCopySmallFontStyleOld};
    --fontStudioButtonsButtonCopySmallLetterSpacing: ${({ theme }) => theme.fontStudioButtonsButtonCopySmallLetterSpacing};
    --fontStudioButtonsButtonCopySmallLineHeight: ${({ theme }) => theme.fontStudioButtonsButtonCopySmallLineHeight};
    --fontStudioButtonsButtonCopySmallParagraphIndent: ${({ theme }) => theme.fontStudioButtonsButtonCopySmallParagraphIndent};
    --fontStudioButtonsButtonCopySmallParagraphSpacing: ${({ theme }) => theme.fontStudioButtonsButtonCopySmallParagraphSpacing};
    --fontStudioButtonsButtonCopySmallTextCase: ${({ theme }) => theme.fontStudioButtonsButtonCopySmallTextCase};
    --fontStudioButtonsButtonCopySmallShortHand: ${({ theme }) => theme.fontStudioButtonsButtonCopySmallShortHand};
    --fontStudioStrongRegularFontSize: ${({ theme }) => theme.fontStudioStrongRegularFontSize};
    --fontStudioStrongRegularTextDecoration: ${({ theme }) => theme.fontStudioStrongRegularTextDecoration};
    --fontStudioStrongRegularFontFamily: ${({ theme }) => theme.fontStudioStrongRegularFontFamily};
    --fontStudioStrongRegularFontWeight: ${({ theme }) => theme.fontStudioStrongRegularFontWeight};
    --fontStudioStrongRegularFontStyle: ${({ theme }) => theme.fontStudioStrongRegularFontStyle};
    --fontStudioStrongRegularFontStretch: ${({ theme }) => theme.fontStudioStrongRegularFontStretch};
    --fontStudioStrongRegularFontStyleOld: ${({ theme }) => theme.fontStudioStrongRegularFontStyleOld};
    --fontStudioStrongRegularLetterSpacing: ${({ theme }) => theme.fontStudioStrongRegularLetterSpacing};
    --fontStudioStrongRegularLineHeight: ${({ theme }) => theme.fontStudioStrongRegularLineHeight};
    --fontStudioStrongRegularParagraphIndent: ${({ theme }) => theme.fontStudioStrongRegularParagraphIndent};
    --fontStudioStrongRegularParagraphSpacing: ${({ theme }) => theme.fontStudioStrongRegularParagraphSpacing};
    --fontStudioStrongRegularTextCase: ${({ theme }) => theme.fontStudioStrongRegularTextCase};
    --fontStudioStrongRegularShortHand: ${({ theme }) => theme.fontStudioStrongRegularShortHand};
    --fontStudioStrongSmallFontSize: ${({ theme }) => theme.fontStudioStrongSmallFontSize};
    --fontStudioStrongSmallTextDecoration: ${({ theme }) => theme.fontStudioStrongSmallTextDecoration};
    --fontStudioStrongSmallFontFamily: ${({ theme }) => theme.fontStudioStrongSmallFontFamily};
    --fontStudioStrongSmallFontWeight: ${({ theme }) => theme.fontStudioStrongSmallFontWeight};
    --fontStudioStrongSmallFontStyle: ${({ theme }) => theme.fontStudioStrongSmallFontStyle};
    --fontStudioStrongSmallFontStretch: ${({ theme }) => theme.fontStudioStrongSmallFontStretch};
    --fontStudioStrongSmallFontStyleOld: ${({ theme }) => theme.fontStudioStrongSmallFontStyleOld};
    --fontStudioStrongSmallLetterSpacing: ${({ theme }) => theme.fontStudioStrongSmallLetterSpacing};
    --fontStudioStrongSmallLineHeight: ${({ theme }) => theme.fontStudioStrongSmallLineHeight};
    --fontStudioStrongSmallParagraphIndent: ${({ theme }) => theme.fontStudioStrongSmallParagraphIndent};
    --fontStudioStrongSmallParagraphSpacing: ${({ theme }) => theme.fontStudioStrongSmallParagraphSpacing};
    --fontStudioStrongSmallTextCase: ${({ theme }) => theme.fontStudioStrongSmallTextCase};
    --fontStudioStrongSmallShortHand: ${({ theme }) => theme.fontStudioStrongSmallShortHand};
    --fontStudioStrongExtraSmallFontSize: ${({ theme }) => theme.fontStudioStrongExtraSmallFontSize};
    --fontStudioStrongExtraSmallTextDecoration: ${({ theme }) => theme.fontStudioStrongExtraSmallTextDecoration};
    --fontStudioStrongExtraSmallFontFamily: ${({ theme }) => theme.fontStudioStrongExtraSmallFontFamily};
    --fontStudioStrongExtraSmallFontWeight: ${({ theme }) => theme.fontStudioStrongExtraSmallFontWeight};
    --fontStudioStrongExtraSmallFontStyle: ${({ theme }) => theme.fontStudioStrongExtraSmallFontStyle};
    --fontStudioStrongExtraSmallFontStretch: ${({ theme }) => theme.fontStudioStrongExtraSmallFontStretch};
    --fontStudioStrongExtraSmallFontStyleOld: ${({ theme }) => theme.fontStudioStrongExtraSmallFontStyleOld};
    --fontStudioStrongExtraSmallLetterSpacing: ${({ theme }) => theme.fontStudioStrongExtraSmallLetterSpacing};
    --fontStudioStrongExtraSmallLineHeight: ${({ theme }) => theme.fontStudioStrongExtraSmallLineHeight};
    --fontStudioStrongExtraSmallParagraphIndent: ${({ theme }) => theme.fontStudioStrongExtraSmallParagraphIndent};
    --fontStudioStrongExtraSmallParagraphSpacing: ${({ theme }) => theme.fontStudioStrongExtraSmallParagraphSpacing};
    --fontStudioStrongExtraSmallTextCase: ${({ theme }) => theme.fontStudioStrongExtraSmallTextCase};
    --fontStudioStrongExtraSmallShortHand: ${({ theme }) => theme.fontStudioStrongExtraSmallShortHand};
    --effectFocusOutline: ${({ theme }) => theme.effectFocusOutline};
    --effectFocus: ${({ theme }) => theme.effectFocus};
    --effectShadowHoverFilter: ${({ theme }) => theme.effectShadowHoverFilter};
    --effectShadowHover: ${({ theme }) => theme.effectShadowHover};
    --effectShadowMenu0: ${({ theme }) => theme.effectShadowMenu0};
    --effectShadowMenu1: ${({ theme }) => theme.effectShadowMenu1};
    --effectShadowMenu0Filter: ${({ theme }) => theme.effectShadowMenu0Filter};
    --effectShadowMenu1Filter: ${({ theme }) => theme.effectShadowMenu1Filter};
    --effectShadowMainFilter: ${({ theme }) => theme.effectShadowMainFilter};
    --effectShadowMain: ${({ theme }) => theme.effectShadowMain};
    --effectNone: ${({ theme }) => theme.effectNone};
    --borderRadius300: ${({ theme }) => theme.borderRadius300};
    --borderRadius400: ${({ theme }) => theme.borderRadius400};
    --borderRadius500: ${({ theme }) => theme.borderRadius500};
    --borderRadius600: ${({ theme }) => theme.borderRadius600};
    --borderRadius700: ${({ theme }) => theme.borderRadius700};
    --borderRadiusNone: ${({ theme }) => theme.borderRadiusNone};
    --borderRadiusRound: ${({ theme }) => theme.borderRadiusRound};
    --spacingHeadingH1: ${({ theme }) => theme.spacingHeadingH1};
    --spacingHeadingH2: ${({ theme }) => theme.spacingHeadingH2};
    --spacingHeadingH3: ${({ theme }) => theme.spacingHeadingH3};
    --spacingListItem: ${({ theme }) => theme.spacingListItem};
    --spacingParagraph: ${({ theme }) => theme.spacingParagraph};
  }
`;

export default GlobalStyleVariables;
