import type { Placement } from "@popperjs/core";
import { useEffect, useRef } from "react";

import { Icon } from "../Icon";
import { Container, Root, SelectionContainer } from "./style";

type Props = {
  autoFocus?: boolean;
  children: React.ReactNode;
  /**
   * If `true`, selection is disabled.
   */
  disabled?: boolean;

  /**
   * Optional placement hint where to open the dropdown containing the options.
   */
  dropdownPlacement?: Placement;

  /**
   * HTML `name` used for grouping the options in the DOM.
   */
  name?: string;

  onClick: () => void;

  /**
   * Optional placeholder to display if no option is selected.
   */
  placeholder?: string;

  trailingIcon?: React.ElementType<React.SVGProps<SVGSVGElement>>;

  /**
   * The value of the selected option, if any.
   */
  value?: string;
};

export type SelectOption = {
  children: React.ReactNode;
  value: string;
};

export function SelectButton({
  autoFocus,
  disabled = false,
  dropdownPlacement,
  name,
  onClick,
  placeholder,
  trailingIcon: TrailingIcon,
  value,
  children,
  ...attributes
}: Props): JSX.Element {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (autoFocus) {
      containerRef.current?.focus();
    }
  }, [autoFocus]);

  return (
    <Root>
      <Container
        {...attributes}
        disabled={disabled}
        ref={containerRef}
        onClick={onClick}
      >
        <SelectionContainer hasSelection>{children}</SelectionContainer>
        <Icon type="triangle_down" />
        <input type="hidden" name={name} value={value} />

        {TrailingIcon && <TrailingIcon />}
      </Container>
    </Root>
  );
}
