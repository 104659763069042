import { Metric, Timeseries } from "./types";

export async function getLabelValues(labelName: string): Promise<string[]> {
  const apiUrl = "http://localhost:9090/api/v1/label/" + labelName + "/values";

  try {
    const response = await fetch(apiUrl);
    const responseJson = await response.json();
    return responseJson?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error occurred while querying Prometheus API:", error);
    return [];
  }
}

type FunctionModuleLabelPair = {
  name: string;
  module: string;
};

/**
 * Tries to auto-detect all tracked functions and modules
 * Uses the /series api to look for all labels associated with function_calls_count_total
 * @returns A list of all unique (module+function) label combinations
 */
export async function getAllTrackedAutometricsFunctions(
  baseUrl: string
): Promise<FunctionModuleLabelPair[]> {
  const apiUrl = `${baseUrl}/api/v1/series`;

  const params = new URLSearchParams();
  params.append(
    "match[]",
    '{__name__=~"function_calls_count_total|function_calls_count"}'
  );

  try {
    const response = await fetch(`${apiUrl}?${params.toString()}`);
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error);
    }

    const series = data.data;

    // Extract all unique (function+module) label combinations
    // const labelCombinations: FunctionModuleLabelPair[] = [];

    return filterUniquePairs(
      series.map((entry: any) => ({
        name: entry.function,
        module: entry.module,
      }))
    );

    // series.forEach((entry: any) => {
    //   const labels: LabelSet = {};

    //   for (const label of Object.keys(entry)) {
    //     if (label !== "__name__") {
    //       labels[label] = entry[label];
    //     }
    //   }

    //   labelCombinations.push(labels);
    // });

    // return labelCombinations;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error occurred while querying Prometheus API:", error);
    return [];
  }
}

// HACK - this is a temporary workaround to get a unique list of all function/module pairs
function filterUniquePairs(
  functionModulePairs: FunctionModuleLabelPair[]
): FunctionModuleLabelPair[] {
  const uniqueEntries: FunctionModuleLabelPair[] = [];

  for (const functionModule of functionModulePairs) {
    const isDuplicate = uniqueEntries.some((otherFunctionModule) => {
      return (
        otherFunctionModule.name === functionModule.name &&
        otherFunctionModule.module === functionModule.module
      );
    });

    if (!isDuplicate) {
      uniqueEntries.push(functionModule);
    }
  }

  return uniqueEntries;
}

/**
 * Service method for querying Prometheus API query_range endpoint
 *
 * @param query - Prometheus query string
 * @param start - ISO 8601 timestamp
 * @param end - ISO 8601 timestamp
 * @returns {Timeseries[]} - Array of timeseries data
 */
export async function queryRange(
  query: string,
  start: string,
  end: string
): Promise<Array<Timeseries>> {
  const apiUrl = "http://localhost:9090/api/v1/query_range";

  const params = new URLSearchParams();

  const step = getStepFromTimeRange(start, end);

  params.append("query", query);
  params.append("start", start);
  params.append("end", end);
  params.append("step", step);

  try {
    const response = await fetch(`${apiUrl}?${params.toString()}`);
    const jsonResponse = await response.json();

    if (!response.ok) {
      throw new Error("Error fetching prometheus data");
    }

    const result = jsonResponse.data.result;

    return mapQueryRangeResultToTimeseries(result);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      "Error occurred while fetching prometheus query_range data:",
      error
    );
    return [];
  }
}

/**
 * Maps each entry in the result array returned from the Prometheus query_range API to a Timeseries object
 *
 * @param result - The result object returned from the Prometheus query_range API
 * @returns {Timeseries[]} - Array of timeseries data that can be consumed by charts
 */
export function mapQueryRangeResultToTimeseries(result: any): Timeseries[] {
  const timeseries: Array<Timeseries> = result.map((entry: any) => {
    const name = entry.metric.__name__;
    const labels = { ...entry.metric };
    delete labels.__name__;

    const metrics: Array<Metric> = entry.values.map((value: Array<any>) => ({
      time: new Date(value[0] * 1000).toISOString(),
      value: Number.parseFloat(value[1]),
      attributes: {},
      resource: {},
    }));

    return {
      name,
      labels,
      metrics,
      attributes: {},
      resource: {},
      visible: true,
    };
  });

  return timeseries;
}

enum StepUnit {
  Seconds = "s",
  Minutes = "m",
  Hours = "h",
}

type StepSize = {
  amount: number;
  unit: StepUnit;
};

/**
 * Create a step size string from a time range, to be used in Prometheus queries
 * This logic was translated from the Rust provider codebase
 *
 * @param start - ISO 8601 timestamp
 * @param end - ISO 8601 timestamp
 * @returns {string} - Prometheus step size string
 */
export function getStepFromTimeRange(start: string, end: string) {
  const from = +new Date(start) / 1000;
  const to = +new Date(end) / 1000;

  let step = (to - from) / 120;
  let unit: StepUnit = StepUnit.Seconds;

  if (step >= 60) {
    step /= 60;
    unit = StepUnit.Minutes;

    if (step >= 60) {
      step /= 60;
      unit = StepUnit.Hours;
    }
  }

  const stepSize: StepSize = {
    amount: Math.ceil(step),
    unit,
  };

  return `${stepSize.amount}${stepSize.unit}`;
}
