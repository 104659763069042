import styled from "styled-components";

import { AttachedPopup } from "../AttachedPopup";

/* stylelint-disable scale-unlimited/declaration-strict-value */
export const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  color: ${(props) => (props.disabled ? "#1f2023" : "rgb(31, 32, 35);")};
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 0;
  border: 1px solid rgba(231, 231, 231, 100%);
  background-color: rgb(243, 243, 243);
  border-radius: 6px;
  padding: 5px 8px 5px 12px;
  height: min-content;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  &:hover {
    ${(props) => (props.disabled ? "" : "background-color: #f9f9f9;")};
  }

  &[aria-invalid="true"] {
    background: ${({ theme }) => theme.colorDanger100};
    border-color: ${({ theme }) => theme.colorDanger500};
  }
`;

export const StyledAttachedPopup = styled(AttachedPopup)`
  min-width: 100%;
`;

export const DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  border: 1px solid rgba(231, 231, 231, 100%);
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  padding: 16px 12px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 25%);
  width: 100%;
`;

export const Root = styled.div`
  height: min-content;
  position: relative;
`;

export const SelectionContainer = styled.span<{ hasSelection: boolean }>`
  ${(props) =>
    props.hasSelection ? "" : `color: ${props.theme.colorBase300};`};
  flex-grow: 1;
  line-height: 20px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0;
`;
