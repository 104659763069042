// ============================================= //
// Theme file                                    //
//                                               //
// Generated by style-dictionary                 //
// This file is generated. PLEASE DO NOT MODIFY. //
// ============================================= //
import React, { useMemo } from "react";
import type { ChartTheme } from "fiberplane-charts";
import { DefaultTheme, ThemeProvider } from "styled-components";

import * as defaultTheme from "./values";
import GlobalStyleVariables from "./GlobalStyleVariables";
import { GlobalStyle } from "./globalStyle";

export * from "./globalStyle";

type Props = {
  children: React.ReactNode;
  themeOverride?: Partial<DefaultTheme>;
};

export function ThemeSelector(props: Props) {
  const { children, themeOverride } = props;
  const theme = useMemo(() => {
    const theme = themeOverride
      ? { ...defaultTheme, ...themeOverride }
      : defaultTheme;
    const chartTheme: ChartTheme & DefaultTheme = {
      ...theme,
      fontAxisFontFamily: theme.fontNotebooksSmallestFontFamily,
      fontAxisFontSize: theme.fontNotebooksSmallestFontSize,
      fontAxisFontStyle: theme.fontNotebooksSmallestFontStyle,
      fontAxisFontWeight: theme.fontNotebooksSmallestFontWeight,
      fontAxisLetterSpacing: theme.fontNotebooksSmallestLetterSpacing,
      fontAxisLineHeight: theme.fontNotebooksSmallestLineHeight,
      fontAxisShortHand: theme.fontNotebooksSmallestShortHand,
      fontControlsLetterSpacing: theme.fontStudioBodyCopySmallLetterSpacing,
      fontControlsShortHand: theme.fontStudioBodyCopySmallShortHand,
      fontLegendLetterSpacing: theme.fontNotebooksSmallestLetterSpacing,
      fontLegendShortHand: theme.fontNotebooksSmallestShortHand,
      fontResultsSummaryLetterSpacing:
        theme.fontStudioButtonsButtonCopySmallLetterSpacing,
      fontResultsSummaryShortHand:
        theme.fontStudioButtonsButtonCopySmallShortHand,
    };
    return chartTheme;
  }, [themeOverride]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyleVariables />
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
}
