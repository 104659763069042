import { AnimatePresence, motion, Variants } from "framer-motion";
import React from "react";
import styled from "styled-components";

import { noop } from "../../../utils";
import { Icon, IconType } from "../Icon";
import { Button } from "./Button";

type SuccessButtonProps = {
  children: React.ReactNode;
  isSuccessful: boolean;
  successIconType?: IconType;
} & React.ComponentPropsWithRef<typeof Button>;

/**
 * Button component that turns into a success styled button based on the
 * `isSuccessful` prop. If successIconType is provided, it will animate in the
 * icon when the button is successful.
 * @param props: SuccessButtonProps
 * @returns Button
 */
export function SuccessButton({
  children,
  isSuccessful,
  successIconType,
  ...buttonAttributes
}: SuccessButtonProps) {
  return (
    <SuccessButtonContainer
      {...buttonAttributes}
      buttonStyle={isSuccessful ? "success" : "base"}
      onClick={isSuccessful ? noop : buttonAttributes.onClick}
      type={isSuccessful ? "button" : buttonAttributes.type}
    >
      {successIconType && (
        <AnimatePresence>
          {isSuccessful && (
            <SuccessIconContainer>
              <SuccessIcon type={successIconType} />
            </SuccessIconContainer>
          )}
        </AnimatePresence>
      )}

      {children}
    </SuccessButtonContainer>
  );
}

const variants: Variants = {
  initial: {
    opacity: 0,
    scale: 0,
    width: "0%",
  },
  animate: {
    opacity: 1,
    scale: 1,
    width: "auto",
  },
};

const SuccessIconContainer = styled(motion.div).attrs({
  variants,
  initial: "initial",
  animate: "animate",
  exit: "initial",
})`
  display: flex;
`;

const SuccessButtonContainer = styled(Button)`
  gap: 0.5em;
`;

const SuccessIcon = styled(Icon)`
  height: 1em;
  width: 1em;
  transform-origin: center;
  scale: 1.5;
`;
