import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { useEffect } from "react";

import { HIDE_MODAL, SHOW_MODAL } from "../../actions";
import { Prometheus } from "../../services";
import {
  clearPromSourcedAutometricsData,
  setAutometricsData,
} from "../../slices";
import { useAppDispatch, useAppSelector } from "../../store";
import { Icon, IconButton, SelectButton } from "../UI";
import { FunctionGraphsPage } from "./FunctionGraphsPage";
import { FunctionTreeList } from "./FunctionTree";
import { selectActivePrometheus } from "../../selectors";

export const FunctionsExplorer = () => {
  const autometricsData = useAppSelector((state) => state.environment.data);
  const instance = useAppSelector(selectActivePrometheus);
  const dispatch = useAppDispatch();

  // TODO - use rtk query to load the labels
  useEffect(() => {
    if (instance?.url) {
      Prometheus.getAllTrackedAutometricsFunctions(instance?.url).then(
        (labels) => {
          dispatch(clearPromSourcedAutometricsData());
          dispatch(
            setAutometricsData(
              labels.map((label) => ({ ...label, source: "PROM" }))
            )
          );
        }
      );
    }
  }, [instance, dispatch]);

  return (
    <FunctionExplorerGrid>
      <FunctionListSection>
        <FunctionTreeListHeader>Explorer</FunctionTreeListHeader>
        <FunctionTreeListActions>
          <EnvironmentSelect />
          {/* <StyledIconButton>
            <StyledIcon type="magnifying_glass" width="36" />
          </StyledIconButton> */}
        </FunctionTreeListActions>

        <FunctionTreeListContainer>
          <FunctionTreeList autometricsData={autometricsData} />
        </FunctionTreeListContainer>
      </FunctionListSection>

      <Routes>
        <Route path=":module/:name" element={<FunctionGraphsPage />} />
      </Routes>
    </FunctionExplorerGrid>
  );
};

const EnvironmentSelect = () => {
  const { instances, selectedInstance } = useAppSelector(
    (state) => state.environment
  );

  const instance = instances.find(
    (instance) => instance.id === selectedInstance
  );

  const dispatch = useAppDispatch();

  return (
    <SelectButton
      onClick={() => {
        const key = "environmentSelect";
        dispatch({
          type: SHOW_MODAL,
          payload: {
            key,
            modal: {
              onRequestClose: () => {
                dispatch({ type: HIDE_MODAL, payload: { key } });
              },
              modal: {
                type: "environmentSelector",
              },
            },
          },
        });
      }}
    >
      {instance?.name}
    </SelectButton>
  );
};

const FunctionExplorerGrid = styled.div`
  display: grid;
  grid-template-columns: 280px 1fr;
  gap: 0;
  height: 100%;
`;

const FunctionListSection = styled.div`
  border-right: solid 1px ${({ theme }) => theme.colorBase300};
  padding: 20px 20px 0px 20px;

  background: ${({ theme }) => theme.colorBase100};
`;

const FunctionTreeListHeader = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  /* grey 550 */
  color: #7f8084;
`;

const FunctionTreeListActions = styled.div<{ hidden?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  & > :first-child {
    flex: 1;
  }
`;

const StyledIconButton = styled(IconButton).attrs({ width: "36" })`
  cursor: pointer;
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colorBase700};
`;

const FunctionTreeListContainer = styled.div`
  margin-top: 26px; /* magic number - when search is unhidden, change to 20px */
`;
