import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled, { css, useTheme } from "styled-components";

import { FunctionData, FunctionTreeNodeData } from "../../../types";
import { useIsActiveNode } from "./hooks";
import { Icon } from "../../UI";
import { getFunctionTreeData } from "./data";

type FunctionTreeNodeProps = {
  node: FunctionTreeNodeData;
};

function FunctionTreeNode({ node }: FunctionTreeNodeProps) {
  const isActiveNode = useIsActiveNode(node);
  const [isExpanded, setIsExpanded] = useState(isActiveNode);
  const { colorBase400, colorBase500 } = useTheme();

  const isModule = node.type === "module";

  return (
    <FunctionTreeListContainer isModule={isModule}>
      {isModule ? (
        <>
          <ModuleName onClick={() => setIsExpanded((expanded) => !expanded)}>
            <Icon
              width={24}
              type={isExpanded ? "triangle_down" : "triangle_right"}
              color={colorBase500}
            />
            <Icon
              width={24}
              type={isExpanded ? "folder_open" : "folder_simple"}
              color={colorBase400}
            />
            <span>{node.name}</span>
          </ModuleName>

          {node.children && isExpanded && (
            <FunctionTreeListChildrenContainer>
              {node.children.map((node) => (
                <FunctionTreeNode key={node.id} node={node} />
              ))}
            </FunctionTreeListChildrenContainer>
          )}
        </>
      ) : (
        <FunctionNavLink to={`/functions/${node.module}/${node.name}`}>
          {({ isActive }) => (
            <FunctionName isActive={isActive}>
              <Icon
                width={24}
                type="file_code"
                color={isActive ? "currentColor" : colorBase400}
              />
              <span>{node.name}</span>
            </FunctionName>
          )}
        </FunctionNavLink>
      )}
    </FunctionTreeListContainer>
  );
}

type DirectoryTreeProps = {
  tree: FunctionTreeNodeData[];
};

function FunctionTree({ tree }: DirectoryTreeProps) {
  return (
    <FunctionTreeContainer>
      {tree.map((node) => (
        <FunctionTreeNode key={node.id} node={node} />
      ))}
    </FunctionTreeContainer>
  );
}

// Example usage
export const FunctionTreeList: React.FC<{
  autometricsData: FunctionData[];
}> = ({ autometricsData }) => {
  const tree = getFunctionTreeData(autometricsData || []);

  // NOTE - if you just wanna use mock data, here you go
  // const tree: FunctionTreeNodeData[] = MOCK_DATA;
  return <FunctionTree tree={tree} />;
};

const gridTemplateColumns = css`
  grid-template-columns: 24px 32px 1fr;
`;

const GridContainer = styled.div`
  display: grid;
  row-gap: 8px;
`;

const FunctionTreeContainer = styled(GridContainer)`
  position: relative;
`;

const FunctionTreeListContainer = styled(GridContainer)<{ isModule: boolean }>(
  ({ isModule }) => isModule && gridTemplateColumns
);

const FunctionTreeListChildrenContainer = styled(GridContainer)`
  grid-column: 2 / -1;
`;

const ModuleName = styled.div(
  ({ theme }) => css`
    ${gridTemplateColumns};

    display: grid;
    grid-column: 1 / -1;
    align-items: center;
    cursor: pointer;
    font: ${theme.fontStudioBodyCopyRegularShortHand};
    letter-spacing: ${theme.fontStudioBodyCopyRegularLetterSpacing};
    color: ${theme.colorBase600};
  `
);

const FunctionName = styled(ModuleName)<{ isActive: boolean }>(
  ({ isActive, theme }) => css`
    grid: 24px 1fr;
    column-gap: 8px;

    ${isActive &&
    css`
      font-weight: ${theme.fontStudioStrongRegularFontWeight}};
      border-radius: ${theme.borderRadius500};
      isolation: isolate;
      color: ${theme.colorBase800};

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        border-radius: ${theme.borderRadius500};
        background-color: ${theme.colorBase200};
        height: 32px;
        width: 100%;
        z-index: -1;
      }
    `}
  `
);

const FunctionNavLink = styled(NavLink)`
  text-decoration: none;
`;
