import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

import { Icon, Button } from "../UI";

export function NavBar() {
  return (
    <TopHeader>
      <Branding>
        <Icon type="autometrics_color" />
        <Icon type="autometrics_logo_type" />
      </Branding>
      <AppLinks>
        <StyledNavLink to="/functions">Functions</StyledNavLink>
        <StyledNavLink to="/settings">Settings</StyledNavLink>
      </AppLinks>
      <ExternalLinks>
        <StyledLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.autometrics.dev/"
        >
          Docs
        </StyledLink>
        <StyledButtonLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/autometrics-dev"
          buttonStyle="baseInverted"
        >
          <Icon type="github_logo_alt" /> Follow us
        </StyledButtonLink>
      </ExternalLinks>
    </TopHeader>
  );
}

const TopHeader = styled.div`
  /* top header */
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;

  position: sticky;
  height: 60px;

  background: ${({ theme }) => theme.colorBase800};
  color: ${({ theme }) => theme.colorBase100};
`;

const Branding = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.colorBackground};
`;

const AppLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: 32px;
`;

const ExternalLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-left: auto;
`;

const StyledLink = styled.a(
  ({ theme }) => css`
    color: ${theme.colorBase100};
    font: ${theme.fontStudioButtonsButtonCopyRegularShortHand};
    letter-spacing: ${theme.fontStudioButtonsButtonCopyRegularLetterSpacing};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  `
);

// TODO - reuse styles from StyledLink
const StyledNavLink = styled(NavLink)(
  ({ theme }) => css`
    color: ${theme.colorBase500};
    font: ${theme.fontStudioButtonsButtonCopyRegularShortHand};
    letter-spacing: ${theme.fontStudioButtonsButtonCopyRegularLetterSpacing};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &.active {
      color: ${theme.colorBase100};
    }
  `
);

const StyledButtonLink = styled(Button).attrs({ asElement: "externalLink" })`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  &:not(:hover, :focus, :active, :disabled) {
    background-color: ${({ theme }) => theme.colorBackground};
  }
`;
