import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { FunctionData } from "../types";

interface Environment {
  id: string;
  name: string;
  url: string | null;
}

export interface EnvironmentState {
  instances: Environment[];
  selectedInstance: string;
  data: FunctionData[];
}

const initialState: EnvironmentState = getInitialState();

export const environmentSlice = createSlice({
  name: "environment",
  initialState,
  reducers: {
    addInstance: (state, action: PayloadAction<Environment>) => {
      // HACK - filter possible duplicates
      state.instances = state.instances.filter(
        (instance) =>
          instance.url !== action.payload.url ||
          instance.name !== action.payload.name
      );
      state.instances.unshift(action.payload);
      // HACK - persist to localStorage
      saveInstances(state.instances);
    },
    // HACK - Action that preserved DOM-parsed autometricized functions
    clearPromSourcedAutometricsData: (state) => {
      state.data = state.data.filter((functionDatum) => {
        return functionDatum.source === "DOM";
      });
    },
    removeInstance: (state, action: PayloadAction<Environment>) => {
      state.instances = state.instances.filter(
        (instance) => instance.id !== action.payload.id
      );
      // HACK - persist to localStorage
      saveInstances(state.instances);
    },
    setAutometricsData: (state, action: PayloadAction<FunctionData[]>) => {
      state.data = mergeAutometricsFunctionLists(state.data, action.payload);
    },
    switchInstance: (state, action: PayloadAction<string>) => {
      state.selectedInstance = action.payload;
      // HACK - persist to localStorage
      saveSelectedInstance(state.selectedInstance);
    },
  },
});

export const {
  addInstance,
  clearPromSourcedAutometricsData,
  removeInstance,
  switchInstance,
  setAutometricsData,
} = environmentSlice.actions;

// NOTE - Reads from localStorage and DOM, so this is pretty impure
// Here be dragons
// * If there is a "selectedInstance" in the localStorage, use that, but only if there's a matching instance in the instances array
// * Else if there is a prometheusUrl defined in the DOM, use that. If there's no matching instance in the instances array, add it.
// * Else if there are instances in localStorage, use the first one
// * Else, the user will end up seeing a splash page that asks them to give a prometheus URL
function getInitialState() {
  const lastSelectedInstance = loadSelectedInstance();

  const state = {
    instances: loadInstances(),
    selectedInstance: loadSelectedInstance(),
    data: getAutometricsDataFromDom(),
  };

  const domPrometheusUrl = getPrometheusUrlFromDom();

  if (domPrometheusUrl) {
    const matchingInstance = state.instances.find(
      (instance) => instance.url === domPrometheusUrl
    );

    if (matchingInstance) {
      state.selectedInstance = matchingInstance.id;
    } else {
      const id = Date.now().toString();
      state.instances.push({
        id,
        name: "Local",
        url: domPrometheusUrl,
      });
      state.selectedInstance = id;
    }
  } else if (state.instances.length > 0) {
    state.selectedInstance = state.instances[0]?.id ?? "";
  }

  if (
    lastSelectedInstance &&
    state.instances.some((i) => i.id === lastSelectedInstance)
  ) {
    state.selectedInstance = lastSelectedInstance;
  }

  return state;
}

function getAutometricsDataFromDom(): FunctionData[] {
  const rawJSON = document
    .querySelector("textarea#autometrics-data")
    //@ts-ignore - We know this should be a textarea whose value is json
    ?.value?.trim();

  if (rawJSON) {
    try {
      const functionData = JSON.parse(rawJSON) as FunctionData[];
      return functionData.map((functionDatum) => ({
        ...functionDatum,
        source: "DOM",
      }));
    } catch (error) {
      alert("Failed to parse autometrics data");
      console.error("Failed to parse autometrics data from DOM:", error);
    }
  }

  return [] as FunctionData[];
}

export function getPrometheusUrlFromDom() {
  const url = document
    .querySelector("textarea#am-prometheus-url")
    //@ts-ignore - We know this should be a textarea containing a string
    ?.value?.trim();

  // TODO - Validate URL
  if (typeof url !== "string") {
    return "";
  }

  return url ?? "";
}

function loadInstances(): Environment[] {
  const localStorageInstancesRaw = localStorage.getItem("dora.instances");
  if (localStorageInstancesRaw) {
    try {
      return JSON.parse(localStorageInstancesRaw) as Environment[];
    } catch (error) {
      console.error("Error loading instances from localStorage:", error);
    }
  }

  return [];
}

function loadSelectedInstance(): string {
  const selectedInstance = localStorage.getItem("dora.selectedInstance");
  if (typeof selectedInstance === "string") {
    return selectedInstance;
  }
  return "";
}

function saveInstances(instances: Environment[]) {
  localStorage.setItem("dora.instances", JSON.stringify(instances));
}

function saveSelectedInstance(instanceId: string) {
  localStorage.setItem("dora.selectedInstance", instanceId);
}

function mergeAutometricsFunctionLists(
  currentFunctionData: FunctionData[],
  nextFunctionData: FunctionData[]
): FunctionData[] {
  const mergedFunctionData = [...currentFunctionData];

  for (const nextFunctionDatum of nextFunctionData) {
    const matchingFunctionDatum = mergedFunctionData.find(
      (currentFunctionDatum) =>
        currentFunctionDatum.name === nextFunctionDatum.name &&
        currentFunctionDatum.module === nextFunctionDatum.module
    );

    if (!matchingFunctionDatum) {
      mergedFunctionData.push(nextFunctionDatum);
    }
  }

  return mergedFunctionData;
}
