import { createElement, forwardRef } from "react";
import type {
  ComponentProps,
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ForwardedRef,
} from "react";
import classNames from "classnames";
import styled, { css, DefaultTheme, useTheme } from "styled-components";
import { Link } from "react-router-dom";

import type { ButtonStyle } from "../../../types";

type ButtonColorSet = {
  color: string;
  backgroundColor: string;
};

type ButtonTheme = {
  normal: ButtonColorSet;
  hover: ButtonColorSet;
  active: ButtonColorSet;
  focus: ButtonColorSet;
  disabled: ButtonColorSet;
};

export function useButtonTheme(
  theme: DefaultTheme,
  buttonStyle: ButtonStyle
): ButtonTheme {
  switch (buttonStyle) {
    case "base":
      return {
        normal: {
          color: theme.colorBackground,
          backgroundColor: theme.colorBase800,
        },
        hover: {
          color: theme.colorBackground,
          backgroundColor: theme.colorBase700,
        },
        active: {
          color: theme.colorBackground,
          backgroundColor: theme.colorBase600,
        },
        focus: {
          color: theme.colorBackground,
          backgroundColor: theme.colorBase800,
        },
        disabled: {
          color: theme.colorBase500,
          backgroundColor: theme.colorBase300,
        },
      };

    case "baseInverted":
      return {
        normal: {
          color: theme.colorBase800,
          backgroundColor: theme.colorBase300,
        },
        hover: {
          color: theme.colorBase800,
          backgroundColor: theme.colorBase400,
        },
        active: {
          color: theme.colorBase800,
          backgroundColor: theme.colorBase500,
        },
        focus: {
          color: theme.colorBase800,
          backgroundColor: theme.colorBase300,
        },
        disabled: {
          color: theme.colorBase500,
          backgroundColor: theme.colorBase200,
        },
      };

    case "primary":
      return {
        normal: {
          color: theme.colorBackground,
          backgroundColor: theme.colorPrimary500,
        },
        hover: {
          color: theme.colorBackground,
          backgroundColor: theme.colorPrimary400,
        },
        active: {
          color: theme.colorBackground,
          backgroundColor: theme.colorPrimary600,
        },
        focus: {
          color: theme.colorBackground,
          backgroundColor: theme.colorPrimary400,
        },
        disabled: {
          color: theme.colorPrimary200,
          backgroundColor: theme.colorPrimary50,
        },
      };

    case "secondary":
      return {
        normal: {
          color: theme.colorBackground,
          backgroundColor: theme.colorSecondary500,
        },
        hover: {
          color: theme.colorBackground,
          backgroundColor: theme.colorSecondary400,
        },
        active: {
          color: theme.colorBackground,
          backgroundColor: theme.colorSecondary600,
        },
        focus: {
          color: theme.colorBackground,
          backgroundColor: theme.colorSecondary400,
        },
        disabled: {
          color: theme.colorBase200,
          backgroundColor: theme.colorSecondary100,
        },
      };

    case "danger":
      return {
        normal: {
          color: theme.colorBackground,
          backgroundColor: theme.colorDanger500,
        },
        hover: {
          color: theme.colorBackground,
          backgroundColor: theme.colorDanger600,
        },
        active: {
          color: theme.colorBackground,
          backgroundColor: theme.colorDanger700,
        },
        focus: {
          color: theme.colorBackground,
          backgroundColor: theme.colorDanger500,
        },
        disabled: {
          color: theme.colorBase500,
          backgroundColor: theme.colorDanger200,
        },
      };

    case "success":
      return {
        normal: {
          color: theme.colorBackground,
          backgroundColor: theme.colorSuccess500,
        },
        hover: {
          color: theme.colorBackground,
          backgroundColor: theme.colorSuccess600,
        },
        active: {
          color: theme.colorBackground,
          backgroundColor: theme.colorSuccess700,
        },
        focus: {
          color: theme.colorBackground,
          backgroundColor: theme.colorSuccess500,
        },
        disabled: {
          color: theme.colorBase500,
          backgroundColor: theme.colorSuccess200,
        },
      };

    case "warning":
      return {
        normal: {
          color: theme.colorBackground,
          backgroundColor: theme.colorWarning500,
        },
        hover: {
          color: theme.colorBackground,
          backgroundColor: theme.colorWarning600,
        },
        active: {
          color: theme.colorBackground,
          backgroundColor: theme.colorWarning700,
        },
        focus: {
          color: theme.colorBackground,
          backgroundColor: theme.colorWarning500,
        },
        disabled: {
          color: theme.colorBase500,
          backgroundColor: theme.colorWarning200,
        },
      };
  }
}

/**
 * Button with initial styling
 */
export const buttonStyling = css`
  --color: var(--button-normal-color);
  --background: var(--button-normal-backgroundColor);

  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-sizing: border-box;
  line-height: normal;
  border-radius: ${({ theme }) => theme.borderRadius500};
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.05s;
  margin: 0;

  /* stylelint-disable-next-line declaration-block-no-shorthand-property-overrides */
  font: var(--button-font);
  padding: var(--button-padding);
  color: var(--color);
  background: var(--background);
  border: 1px solid var(--background);

  :focus,
  :hover,
  :active,
  .active {
    cursor: pointer;
  }

  :focus {
    border-color: ${({ theme }) => theme.colorPrimary500};
    outline: ${({ theme }) => theme.effectFocusOutline};

    --background: var(--button-focus-backgroundColor);
    --color: var(--button-focus-color);
  }

  :hover {
    --background: var(--button-hover-backgroundColor);
    --color: var(--button-hover-color);
  }

  &.active,
  &:active {
    --background: var(--button-active-backgroundColor);
    --color: var(--button-active-color);
  }

  :disabled {
    cursor: default;

    --background: var(--button-disabled-backgroundColor);
    --color: var(--button-disabled-color);
  }
`;

export const StyledButton = styled.button`
  ${buttonStyling}
`;

export const StyledLink = styled(Link)`
  ${buttonStyling}
`;

export const StyledAnchor = styled.a`
  ${buttonStyling}
`;

type ButtonStyleProps = {
  buttonStyle?: ButtonStyle;
  active?: boolean;
  buttonSize?: "small";
};

type AsButton = ButtonHTMLAttributes<HTMLButtonElement> & {
  asElement?: "button";
};

type AsLink = ComponentProps<typeof StyledLink> & {
  asElement: "link";
};

type AsAnchor = AnchorHTMLAttributes<HTMLAnchorElement> & {
  asElement: "externalLink";
};

type ButtonProps = AsButton | AsLink | AsAnchor;

export type ButtonComponentProps = ButtonProps & ButtonStyleProps;

function useButtonCssVariables(
  buttonSize: ButtonComponentProps["buttonSize"],
  buttonStyle: ButtonStyle
) {
  const theme = useTheme();
  const buttonTheme = useButtonTheme(theme, buttonStyle);

  return {
    "--button-font":
      buttonSize === "small"
        ? theme.fontStudioButtonsButtonCopySmallShortHand
        : theme.fontStudioButtonsButtonCopyRegularShortHand,
    "--button-padding": buttonSize === "small" ? "7px 11px" : "9px 15px",
    "--button-normal-color": buttonTheme.normal.color,
    "--button-normal-backgroundColor": buttonTheme.normal.backgroundColor,
    "--button-hover-color": buttonTheme.hover.color,
    "--button-hover-backgroundColor": buttonTheme.hover.backgroundColor,
    "--button-active-color": buttonTheme.active.color,
    "--button-active-backgroundColor": buttonTheme.active.backgroundColor,
    "--button-focus-color": buttonTheme.focus.color,
    "--button-focus-backgroundColor": buttonTheme.focus.backgroundColor,
    "--button-disabled-color": buttonTheme.disabled.color,
    "--button-disabled-backgroundColor": buttonTheme.disabled.backgroundColor,
  };
}

function ButtonComponent(
  props: ButtonComponentProps,
  ref: ForwardedRef<HTMLElement>
): JSX.Element {
  const {
    buttonStyle = "base",
    buttonSize,
    asElement = "button",
    className = "",
    style = {},
    active = false,
    children,
    ...rest
  } = props;

  const cssVariables = useButtonCssVariables(buttonSize, buttonStyle);

  const newStyle = { ...style, ...cssVariables };

  const elementProps = {
    ...rest,
    ref,
    style: newStyle,
    className: classNames(className, active && "active"),
  };

  switch (asElement) {
    case "link":
      return <StyledLink {...elementProps}>{children}</StyledLink>;

    case "externalLink":
      return <StyledAnchor {...elementProps}>{children}</StyledAnchor>;

    case "button":
      return createElement(
        StyledButton,
        elementProps as Omit<AsButton, "asElement">,
        children
      );
  }
}

export const Button = forwardRef(ButtonComponent);
