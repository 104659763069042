import type { ContextMenuInfo, ModalState, UiState } from "../state";
import type { TooltipAnchor, TooltipOptions } from "../types";

export const HIDE_ACTIVE_CONTEXT_MENU = "ui/hideActiveContextMenu";
export const HIDE_ACTIVE_TOOLTIP = "ui/hideActiveTooltip";
export const HIDE_ENGAGE_MENU = "ui/hideEngageMenu";
export const HIDE_MODAL = "ui/hideModal";
export const HIDE_SIDE_MENU = "ui/hideSideMenu";
export const SET_ACTIVE_CONTEXT_MENU = "ui/setActiveContextMenu";
export const SET_ACTIVE_TOOLTIP = "ui/setActiveTooltip";
export const TOGGLE_DATE_PICKER_VISIBLE = "ui/setDatepickerVisible";
export const SET_DRAGGED_ELEMENT = "ui/setDraggedElement";
export const SET_NOTEBOOK_FOCUSED = "ui/setNotebookFocused";
export const SET_SIDE_MENU_BEHAVIOR = "ui/setSideMenuBehavior";
export const SET_SIDE_MENU_BUTTON_VISIBLE_OUTSIDE =
  "ui/setSideMenuButtonVisibleOutside";
export const SET_VIEWS_DISPLAY_TYPE = "ui/setViewsDisplayType";
export const SET_WINDOW_FOCUSED = "ui/setWindowFocused";
export const SHOW_ENGAGE_MENU = "ui/showEngageMenu";
export const SHOW_MODAL = "ui/showModal";
export const SHOW_SIDE_MENU = "ui/showSideMenu";
export const UPDATE_MODAL = "ui/updateModal";

export type UiAction =
  | HideActiveContextMenu
  | HideActiveTooltipAction
  | HideEngageMenuAction
  | HideModalAction
  | HideSideMenuAction
  | SetActiveContextMenu
  | SetActiveTooltipAction
  | SetDatepickerOpenAction
  | SetDraggedElementAction
  | SetNotebookFocusedAction
  | SetSideMenuBehaviorAction
  | SetSideMenuButtonVisibleOutsideAction
  | SetViewsDisplayType
  | SetWindowFocusedAction
  | ShowEngageMenuAction
  | ShowModalAction
  | ShowSideMenuAction
  | UpdateModalAction;

type HideActiveContextMenu = {
  type: typeof HIDE_ACTIVE_CONTEXT_MENU;
};

type HideActiveTooltipAction = {
  type: typeof HIDE_ACTIVE_TOOLTIP;
};

type HideEngageMenuAction = {
  type: typeof HIDE_ENGAGE_MENU;
};

export type HideModalAction = {
  type: typeof HIDE_MODAL;
  payload: {
    key: string;
  };
};

type HideSideMenuAction = {
  type: typeof HIDE_SIDE_MENU;
};

type SetActiveContextMenu = {
  type: typeof SET_ACTIVE_CONTEXT_MENU;
  payload: ContextMenuInfo;
};

type SetActiveTooltipAction = {
  type: typeof SET_ACTIVE_TOOLTIP;
  payload: {
    anchor: TooltipAnchor;
    content: React.ReactNode;
    options: TooltipOptions;
  };
};

type SetDatepickerOpenAction = {
  type: typeof TOGGLE_DATE_PICKER_VISIBLE;
};

type SetDraggedElementAction = {
  type: typeof SET_DRAGGED_ELEMENT;
  payload: HTMLElement | null;
};

type SetNotebookFocusedAction = {
  type: typeof SET_NOTEBOOK_FOCUSED;
  payload: boolean;
};

type SetSideMenuBehaviorAction = {
  type: typeof SET_SIDE_MENU_BEHAVIOR;
  payload: UiState["sideMenuBehavior"];
};

type SetSideMenuButtonVisibleOutsideAction = {
  type: typeof SET_SIDE_MENU_BUTTON_VISIBLE_OUTSIDE;
  payload: boolean;
};

type SetViewsDisplayType = {
  type: typeof SET_VIEWS_DISPLAY_TYPE;
  payload: UiState["viewsDisplayType"];
};

type SetWindowFocusedAction = {
  type: typeof SET_WINDOW_FOCUSED;
  payload: boolean;
};

type ShowEngageMenuAction = {
  type: typeof SHOW_ENGAGE_MENU;
};

export type ShowModalAction = {
  type: typeof SHOW_MODAL;
  payload: {
    key: string;
    modal: ModalState;
  };
};

type ShowSideMenuAction = {
  type: typeof SHOW_SIDE_MENU;
};

export type UpdateModalAction = {
  type: typeof UPDATE_MODAL;
  payload: {
    key: string;
    modal: ModalState;
  };
};

export function hideActiveTooltip(): UiAction {
  return {
    type: HIDE_ACTIVE_TOOLTIP,
  };
}

export function hideEngageMenu(): UiAction {
  return {
    type: HIDE_ENGAGE_MENU,
  };
}

export function hideSideMenu(): UiAction {
  return {
    type: HIDE_SIDE_MENU,
  };
}

export function setActiveTooltip(
  tooltip: SetActiveTooltipAction["payload"]
): UiAction {
  return {
    type: SET_ACTIVE_TOOLTIP,
    payload: tooltip,
  };
}

export function toggleDatepickerVisibility(): UiAction {
  return {
    type: TOGGLE_DATE_PICKER_VISIBLE,
  };
}

export function setDraggedElement(element: HTMLElement | null): UiAction {
  return {
    type: SET_DRAGGED_ELEMENT,
    payload: element,
  };
}

export function setNotebookFocused(isNotebookFocused: boolean): UiAction {
  return {
    type: SET_NOTEBOOK_FOCUSED,
    payload: isNotebookFocused,
  };
}

export function setSideMenuButtonVisibleOutside(
  visibleOutside: boolean
): UiAction {
  return {
    type: SET_SIDE_MENU_BUTTON_VISIBLE_OUTSIDE,
    payload: visibleOutside,
  };
}

export function setWindowFocused(isWindowFocused: boolean): UiAction {
  return {
    type: SET_WINDOW_FOCUSED,
    payload: isWindowFocused,
  };
}

export function showEngageMenu(): UiAction {
  return {
    type: SHOW_ENGAGE_MENU,
  };
}

export function showSideMenu(): UiAction {
  return {
    type: SHOW_SIDE_MENU,
  };
}

export function updateModal(key: string, modal: ModalState): UiAction {
  return {
    type: UPDATE_MODAL,
    payload: { key, modal },
  };
}
