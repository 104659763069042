import { useContext, useState } from "react";
import styled from "styled-components";

import { addInstance } from "../../slices";
import { useAppDispatch, useAppSelector } from "../../store";
import { EnvironmentAddModalProps } from "../../types";
import { Button, Modal, ModalContext, TextInput } from "../UI";

export const EnvironmentAddModal = (props: EnvironmentAddModalProps) => {
  const { requestClose } = useContext(ModalContext);
  const dispatch = useAppDispatch();
  const { instances } = useAppSelector((state) => state.environment);

  const [form, setForm] = useState({
    name: "",
    url: "",
  });

  const [isAdding, setIsAdding] = useState(false);
  return (
    <StyledModal>
      <Title>Add a Prometheus Environment</Title>
      <Container>
        <TextInput
          type="text"
          value={form.name}
          placeholder="Name"
          onChange={(event) =>
            setForm((currentState) => ({
              ...currentState,
              name: event.target.value,
            }))
          }
        />
        <TextInput
          type="text"
          value={form.url}
          placeholder="e.g., http://localhost:9090"
          onChange={(event) =>
            setForm((currentState) => ({
              ...currentState,
              url: event.target.value,
            }))
          }
        />
        <Button
          onClick={() => {
            dispatch(addInstance({ ...form, id: String(Date.now()) }));
            setIsAdding(false);
            setForm({ name: "", url: "" });
            requestClose();
          }}
        >
          Save
        </Button>
      </Container>
    </StyledModal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 16px;
  }
`;

const Title = styled.header`
  display: flex;
  justify-content: space-between;
  margin: 0 0 16px;
`;

const StyledModal = styled(Modal)``;
