import { FunctionData, FunctionTreeNodeData } from "../../../types";

export function getFunctionTreeData(
  functions: FunctionData[]
): FunctionTreeNodeData[] {
  const modules: Record<string, FunctionTreeNodeData> = {};

  for (const function_ of functions) {
    const { name: functionName, module: moduleName } = function_;
    const module = modules[moduleName] || {
      id: Object.keys(modules).length + 1,
      name: moduleName,
      module: moduleName,
      type: "module",
      children: [],
    };

    module.children?.push({
      id: moduleName + functionName, // FIXME - could be duplicate if we are not tracking module...
      name: functionName,
      module: moduleName,
      type: "function",
    });

    modules[moduleName] = module;
  }

  return Object.values(modules);
}

// export const MOCK_DATA: FunctionTreeNodeData[] = [
//   {
//     id: 1,
//     name: "authentication_user_mgmt",
//     type: "module",
//     children: [
//       {
//         id: 3,
//         name: "authentication",
//         type: "module",
//         children: [
//           { id: 4, name: "login", type: "function" },
//           { id: 5, name: "logout", type: "function" },
//           { id: 51, name: "register", type: "function" },
//           { id: 52, name: "forgot_password", type: "function" },
//           { id: 53, name: "change_password", type: "function" },
//         ],
//       },

//       // { id: 2, name: "analytics", type: "module" },
//     ],
//   },
// ];

// const MOCK_RAW_DATA: FunctionData[] = [
//   { name: "login", module: "authentication" },
//   { name: "logout", module: "authentication" },
//   { name: "register", module: "authentication" },
//   { name: "forgot_password", module: "authentication" },
//   { name: "change_password", module: "authentication" },
//   { name: "track_user", module: "analytics" },
//   { name: "button_click", module: "analytics" },
// ];
