import type { Timeseries } from "fiberplane-charts";
import { useParams } from "react-router-dom";
import differenceInMinutes from "date-fns/differenceInMinutes";

import type { MetricReportType } from "./common";
import { useQueryPrometheusQuery } from "../../../api";
import { useAppSelector } from "../../../store";
import {
  createErrorRatioQuery,
  createLatencyQuery,
  createRequestCountQuery,
  createRequestRateQuery,
} from "./data";

type TimeRangeArguments = {
  from: string;
  to: string;
};

type FunctionDataType = {
  functionName: string;
  prometheusData: Array<
    ReturnType<typeof useQueryPrometheusQuery> & {
      description: string;
      title: string;
      metrics: Array<{
        reportType: MetricReportType;
        unit?: string;
        value?: string;
      }>;
    }
  >;
};

/**
 * Hook to fetch Prometheus data based on a given time range. It checks the
 * current route to find the function name and module name, and then fetches
 * the data for that function.
 */
export function usePrometheusData(timeRange: TimeRangeArguments) {
  const { name: functionName, module } = useParams();
  const autometricsData = useAppSelector((state) => state.environment.data);

  const matchedData = autometricsData.find(
    (entry) => entry.module === module && entry.name === functionName
  );
  const matchedFunctionName = matchedData?.name ?? "";
  const matchedModuleName = matchedData?.module ?? "";

  const timeRangeInMinutes = differenceInMinutes(
    new Date(timeRange.to),
    new Date(timeRange.from)
  );

  const queriedRequestRate = useQueryPrometheusQuery({
    query: createRequestRateQuery({
      nodeIdentifier: matchedFunctionName,
      nodeType: "function",
      moduleName: matchedModuleName,
    }),
    start: timeRange.from,
    end: timeRange.to,
  });

  const queriedErrorRatio = useQueryPrometheusQuery({
    query: createErrorRatioQuery({
      nodeIdentifier: matchedFunctionName,
      nodeType: "function",
      moduleName: matchedModuleName,
    }),
    start: timeRange.from,
    end: timeRange.to,
  });

  const queriedLatency = useQueryPrometheusQuery({
    query: createLatencyQuery({
      nodeIdentifier: matchedFunctionName,
      nodeType: "function",
      moduleName: matchedModuleName,
    }),
    start: timeRange.from,
    end: timeRange.to,
  });

  const countQuery = useQueryPrometheusQuery({
    query: createRequestCountQuery({
      nodeIdentifier: matchedFunctionName,
      nodeType: "function",
      moduleName: matchedModuleName,
      timeRangeInMinutes,
    }),
    start: timeRange.from,
    end: timeRange.to,
  });

  // FIXME: a bit of a dirty way adding some additional data to the cards
  const functionData: FunctionDataType = {
    functionName: matchedFunctionName,
    prometheusData: [
      {
        ...queriedRequestRate,
        title: "Request rate",
        description:
          "Rate of calls to the function per second, averaged over 5 minute windows",
        metrics: [
          {
            reportType: "latest",
            unit: "calls/sec",
            value: getLatestValue(queriedRequestRate.data),
          },
          {
            reportType: "sum",
            unit: "calls",
            value: getLatestValue(countQuery.data, 0),
          },
        ],
      },
      {
        ...queriedErrorRatio,
        title: "Error ratio",
        description:
          "Percentage of calls to the function that return errors, averaged over 5 minute windows",
        metrics: [
          {
            reportType: "latest",
            unit: undefined,
            value: getLatestValueAsPercentage(queriedErrorRatio.data),
          },
        ],
      },
      {
        ...queriedLatency,
        title: "Latency",
        description:
          "99th and 95th percentile latency or response time for the function. For example, if the 99th percentile latency is 500 milliseconds, that means that 99% of calls to the function are handled within 500ms or less.",
        metrics: [
          {
            reportType: "latest",
            unit: "seconds",
            value: getLatestValue(queriedLatency.data),
          },
        ],
      },
    ],
  };

  return functionData;
}

function getLatestValueFromData(data?: Array<Timeseries>) {
  const [metricsData] = data ?? [];
  const metrics = metricsData?.metrics;

  return metrics?.[metrics.length - 1]?.value;
}

function getLatestValue(
  data?: Array<Timeseries>,
  maximumFractionDigits: number = 2
) {
  const value = getLatestValueFromData(data);
  if (value) {
    return value.toLocaleString(undefined, { maximumFractionDigits });
  }
}

function getLatestValueAsPercentage(data?: Array<Timeseries>) {
  const value = getLatestValueFromData(data);
  if (value) {
    return `${(value * 100).toLocaleString(undefined, {
      maximumFractionDigits: 2,
    })}%`;
  }
}
