import styled from "styled-components";

import { ModalButtonBar } from "./ModalButtonBar";

export const ModalDescription = styled.p`
  font: ${({ theme }) => theme.fontStudioBodyCopyRegularShortHand};
  margin: 0;

  &:not(:first-child:last-child) {
    margin: 0 0 16px;
  }
`;

export const ModalContent = styled.section`
  min-width: fit-content;
  position: relative;
  overflow-y: auto;
  padding: 24px;

  & + ${ModalButtonBar} {
    padding-top: 0;
  }
`;
