import { useSelector } from "react-redux";
import { TimeRange } from "fiberplane-charts";
import styled from "styled-components";

import { Button } from "../UI";
import { DatePickerContent } from "./DatePickerContent";
import { timeRangeOptions } from "./TimeRangePresets";
import { useHandler } from "../../hooks";
import { Clock } from "./Clock";
import { CaretDown } from "./CaretDown";
import { pxToEm } from "./utils";
import { selectIsDatePickerOpen } from "../../selectors";
import { dispatch } from "../../store";
import { toggleDatepickerVisibility } from "../../actions";

type Props = {
  timeRange: TimeRange;
  onChange: (timeRange: TimeRange) => void;
};

export function DatePicker(props: Props) {
  const isDatePickerOpen = useSelector(selectIsDatePickerOpen);

  const toggleDatePicker = useHandler(() => {
    dispatch(toggleDatepickerVisibility());
  });

  const handler = useHandler((timeRange: TimeRange) => {
    toggleDatePicker();
    props.onChange(timeRange);
  });

  return (
    <>
      <StyledButton buttonStyle="baseInverted" onClick={toggleDatePicker}>
        <Clock />
        {toFormattedTimeRange(props.timeRange)}
        <CaretDown />
      </StyledButton>

      {isDatePickerOpen && (
        <Content>
          <DatePickerContent timeRange={props.timeRange} onChange={handler} />
        </Content>
      )}
    </>
  );
}

// HACK - magick numbers (knows the padding of the container for app)
const Content = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  transform: translate(-20px, ${pxToEm(36)});
  min-width: 430px;
`;

const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.colorBackground};
  display: flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
`;

const TWO_MINS = 2 * 60 * 1000;
const ONE_DAY = 24 * 60 * 60 * 1000;

function toFormattedTimeRange(timeRange: TimeRange) {
  const startTimeNumeric = +new Date(timeRange.from);
  const endTimeNumeric = +new Date(timeRange.to);

  // If end is less than 2 minutes ago, and the time range is one of our pre fabricated options, then render the human language string
  const isEndTimeLessthanTwoMinutesAgo = endTimeNumeric > Date.now() - TWO_MINS;
  if (isEndTimeLessthanTwoMinutesAgo) {
    const timeRangeDuration = endTimeNumeric - startTimeNumeric;
    const matchingTimeRangeOption = timeRangeOptions.find(
      (option) => option.value === timeRangeDuration
    );
    if (matchingTimeRangeOption) {
      return matchingTimeRangeOption.label;
    }
  }

  // If start date is less than 24 hours ago, render the time without the date
  const isStartTimeLessThan24HoursAgo = startTimeNumeric > Date.now() - ONE_DAY;
  if (isStartTimeLessThan24HoursAgo) {
    return (
      <>
        {formatLocaleTime(timeRange.from)} &mdash;{" "}
        {formatLocaleTime(timeRange.to)}
      </>
    );
  }

  // Otherwise, render the absolute time range
  return (
    <>
      {timeRange.from} &mdash; {timeRange.to}
    </>
  );
}

function formatLocaleTime(timestamp: string) {
  const date = new Date(timestamp);
  // If the date is less than 24 hours ago, show the time
  return date.toLocaleTimeString();
}
