import {
  createApi,
  BaseQueryFn,
  FetchBaseQueryError,
  FetchArgs,
} from "@reduxjs/toolkit/query/react";
// Import fetchBaseQuery from query instead of query/react, otherwise parcel builds break, see:
// https://github.com/parcel-bundler/parcel/issues/7622
import { fetchBaseQuery } from "@reduxjs/toolkit/query";

import { Prometheus, Timeseries } from "../services";
import { selectActivePrometheus } from "../selectors";
import { RootState } from "../state";

/**
 * This is a custom baseQuery that uses the baseUrl from the Prometheus config.
 * We need this because the baseUrl is dynamic (the user can configure it themselves)
 */
const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = (args, WebApi, extraOptions) => {

  const instance = selectActivePrometheus(WebApi.getState() as RootState);
  console.log("dynamicBaseQuery", WebApi.getState());

  const baseUrl = instance?.url;

  if (!baseUrl) {
    // eslint-disable-next-line no-console
    console.error("No baseUrl found in Prometheus config, throwing error");
    throw new Error("Must configure Prometheus URL");
  }

  const rawBaseQuery = fetchBaseQuery({ baseUrl });
  return rawBaseQuery(args, WebApi, extraOptions);
};

export const baseApi = createApi({
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    queryPrometheus: builder.query<
      Timeseries[],
      { query: string; start: string; end: string }
    >({
      query: ({ query, start, end }) => ({
        url: "/api/v1/query_range",
        method: "GET",
        params: {
          query,
          start,
          end,
          step: Prometheus.getStepFromTimeRange(start, end),
        },
      }),
      transformResponse: (response: any) => {
        return Prometheus.mapQueryRangeResultToTimeseries(response.data.result);
      },
    }),
    pingPrometheus: builder.query<boolean, void>({
      query: () => ({
        url: "/api/v1/query",
        method: "GET",
        params: {
          query: "up",
        },
      }),
      transformResponse: () => {
        return true;
      },
    }),
  }),
  reducerPath: "api",
});

export const { useQueryPrometheusQuery, usePingPrometheusQuery } = baseApi;
