import { useState, useEffect, useRef } from "react";
import useUnmount from "react-use/lib/useUnmount";

import { usePrevious } from "./usePrevious";

/**
 * Hook that throttles `isLoading === true` for a set amount of time
 *
 * **Example**
 * ```ts
 * // Imagine we want a button that will be disabled while we fetch some data
 * //
 * const FetchListOfThingsButton = () => {
 *   // Actual loading state for a request
 *   const isLoading = useSelector(selectIsFetchingThings);
 *   // Minimum amount of time we should show the loading state
 *   const minLoadTimeMs = 3000;
 *   // Throttled `isLoading` value
 *   const throttledIsLoading = useThrottledLoadingState(
 *     isLoading,
 *     minLoadTimeMs
 *   );
 *
 *   return (
 *     <button
 *        // This button will stay disabled for a minimum of `minLoadTimeMs`
 *        disabled={throttledIsLoading}
 *        onClick={() => dispatch(fetchThings)}
 *     >
 *       Fetch Things
 *     </button>
 *   );
 * };
 * ```
 */
export function useThrottledLoadingState(isLoading: boolean, delayMs: number) {
  const [throttledIsLoading, setThrottledIsLoading] = useState(isLoading);
  const prevIsLoading = usePrevious(isLoading);
  const timeout = useRef<null | ReturnType<typeof setTimeout>>(null);

  useEffect(() => {
    if (
      (prevIsLoading === false || prevIsLoading === undefined) &&
      isLoading === true
    ) {
      setThrottledIsLoading(true);
      timeout.current = setTimeout(() => {
        setThrottledIsLoading(false);
        timeout.current = null;
      }, delayMs);
    }
  }, [isLoading, prevIsLoading, delayMs]);

  useUnmount(() => {
    timeout.current && clearTimeout(timeout.current);
  });

  return isLoading || throttledIsLoading;
}
