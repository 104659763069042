import { useContext, useState } from "react";
import styled from "styled-components";

import { addInstance, switchInstance } from "../../slices";
import { useAppDispatch, useAppSelector } from "../../store";
import { EnvironmentSelectorModalProps } from "../../types";
import { Button, Modal, ModalContext, TextInput } from "../UI";
import { PrometheusEnvironmentsList } from "../Settings";

export const EnvironmentSelectorModal = (
  props: EnvironmentSelectorModalProps
) => {
  const { requestClose } = useContext(ModalContext);
  const dispatch = useAppDispatch();
  const { instances, selectedInstance } = useAppSelector(
    (state) => state.environment
  );

  const activeInstance = instances.find(
    (instance) => instance.id === selectedInstance
  );

  const [form, setForm] = useState({
    name: "",
    url: "",
  });

  const [isAdding, setIsAdding] = useState(false);
  return (
    <StyledModal>
      <Title>
        {isAdding ? "Add a Prometheus Env" : "Select a Prometheus Env"}
      </Title>
      {isAdding ? (
        <Container>
          <TextInput
            type="text"
            value={form.name}
            placeholder="Name"
            onChange={(event) =>
              setForm((currentState) => ({
                ...currentState,
                name: event.target.value,
              }))
            }
          />
          <TextInput
            type="text"
            value={form.url}
            placeholder="e.g., http://localhost:9090"
            onChange={(event) =>
              setForm((currentState) => ({
                ...currentState,
                url: event.target.value,
              }))
            }
          />
          <Button
            onClick={() => {
              if (form.url === "") {
                requestClose();
                return;
              }

              dispatch(addInstance({ ...form, id: String(Date.now()) }));
              setIsAdding(false);
              setForm({ name: "", url: "" });
            }}
          >
            Save
          </Button>
        </Container>
      ) : (
        <Container>
          <PrometheusEnvironmentsList
            instances={instances}
            activeInstance={activeInstance}
            onEnvironmentClick={(instance) => {
              dispatch(switchInstance(instance.id));
              requestClose();
            }}
          />
          <Button
            buttonStyle="baseInverted"
            onClick={() => {
              setIsAdding(true);
            }}
          >
            Add
          </Button>
        </Container>
      )}
    </StyledModal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 16px;
  }
`;

const Title = styled.header`
  display: flex;
  justify-content: space-between;
  margin: 0 0 16px;
`;

const StyledModal = styled(Modal)``;
