import styled, { css } from "styled-components";

export type MetricReportType = "latest" | "average" | "sum";

export const BRAND_COLORS = [
  "#991577",
  "#E04969",
  "#F1A04F",
  "#EA6E4B",
] as const;

export function getBrandColorForIndex(index: number) {
  const safeIndex = (index < 0 ? 0 : index) % BRAND_COLORS.length;
  return BRAND_COLORS[safeIndex]!;
}

export const INITIAL_TIMERANGE = {
  from: fifteenMinutesAgo().toISOString(),
  to: new Date().toISOString(),
};

function fifteenMinutesAgo(d?: Date) {
  d = d ? new Date(d) : new Date();
  d.setMinutes(d.getMinutes() - 15);
  return d;
}

export const Container = styled.div(
  ({ theme }) => css`
    padding: var(--container-spacing);
    border-radius: ${theme.borderRadius500};
    background-color: ${theme.colorBase100};
  `
);
