/// <reference types="@welldone-software/why-did-you-render" />

import React from "react";

if (process.env.NODE_ENV === "development" && process.env.never === "false") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");

  const ReactRedux = require("react-redux/lib");
  const ReactUseLockBodyScroll = require("react-use/lib/useLockBodyScroll");
  const ReactUseAsync = require("react-use/lib/useAsync");
  const ReactUseAsyncRetry = require("react-use/lib/useAsyncRetry");
  const ReactUseClickAway = require("react-use/lib/useClickAway");
  const ReactUseFavicon = require("react-use/lib/useFavicon");
  const ReactUseLatest = require("react-use/lib/useLatest");
  const ReactUseLifecycles = require("react-use/lib/useLifecycles");
  const ReactUseMeasure = require("react-use/lib/useMeasure");
  const ReactUseMedia = require("react-use/lib/useMedia");
  const ReactUseNetworkState = require("react-use/lib/useNetworkState");
  const ReactUseThrottle = require("react-use/lib/useThrottle");

  const UseKeyboardHandlers = require("./hooks/useKeyboardHandlers");
  const UseKeyPressEvent = require("./hooks/useKeyPressEvent");
  const UseShake = require("./components/UI/useShake");

  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackExtraHooks: [
      [ReactRedux, "useSelector"],
      [ReactUseAsync, "useAsync"],
      [ReactUseAsyncRetry, "useAsyncRetry"],
      [ReactUseFavicon, "useFavicon"],
      [ReactUseLockBodyScroll, "useLockBodyScroll"],
      [ReactUseClickAway, "useClickAway"],
      [ReactUseLatest, "useLatest"],
      [ReactUseLifecycles, "useLifecycles"],
      [ReactUseMeasure, "useMeasure"],
      [ReactUseMedia, "useMedia"],
      [ReactUseNetworkState, "useNetworkState"],
      [ReactUseThrottle, "useThrottle"],
      [UseKeyboardHandlers, "useKeyboardHandlers"],
      [UseKeyPressEvent, "useKeyPressEvent"],
      [UseShake, "useShake"],
    ],
  });
}
