import { Metric, Timeseries } from "../../../services";

// Generate a random number between a specified range
function getRandomNumber(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

// Generate fake timeseries data
export function generateFakeTimeseriesData(start: string, end: string) {
  const data = [];

  const labels = {
    label1: "value1",
    label2: "value2",
  };

  for (let i = 0; i < 1; i++) {
    const timeseries: Timeseries = {
      name: `Timeseries ${i + 1}`,
      labels,
      metrics: generateRandomMetricData(start, end, 15_000), // HACK
      visible: true,
      attributes: {},
      resource: {},
    };

    data.push(timeseries);
  }

  return data;
}

/**
 * Generate an array of random Metric data over a given span of time.
 */
export function generateRandomMetricData(
  start: string,
  end: string,
  interval: number
) {
  const metricData: Metric[] = [];
  const startTime = new Date(start);
  const endTime = new Date(end);
  let currentTime = new Date(startTime);

  while (currentTime <= endTime) {
    const metric: Metric = {
      time: currentTime.toISOString(),
      value: getRandomNumber(0, 100),
      attributes: {},
      resource: {},
    };

    metricData.push(metric);

    currentTime = new Date(currentTime.getTime() + interval);
  }

  return metricData;
}

// NOTE - Copy-pasted-modified query builders from autometrics-ts
//        (I modified to use module label in queries when present)

type NodeType = "function" | "method";

type QueryArguments = {
  nodeIdentifier: string;
  nodeType: NodeType;
  moduleName?: string;
};

export function createLatencyQuery({
  nodeIdentifier,
  nodeType,
  moduleName,
}: QueryArguments) {
  const latency = `sum by (le, function, module) (rate(${nodeType}_calls_duration_bucket{${nodeType}="${nodeIdentifier}"${
    moduleName && ', module="' + moduleName + '"'
  }}[5m]))`;
  return `histogram_quantile(0.99, ${latency}) or histogram_quantile(0.95, ${latency})`;
}

export function createRequestRateQuery({
  nodeIdentifier,
  nodeType,
  moduleName,
}: QueryArguments) {
  return `sum by (function, module) (rate(${nodeType}_calls_count_total{${nodeType}="${nodeIdentifier}"${
    moduleName && ', module="' + moduleName + '"'
  }}[5m]))`;
}

export function createErrorRatioQuery({
  nodeIdentifier,
  nodeType,
  moduleName,
}: QueryArguments) {
  const requestQuery = createRequestRateQuery({
    nodeIdentifier,
    nodeType,
    moduleName,
  });
  return `sum by (function, module) (rate(${nodeType}_calls_count_total{${nodeType}="${nodeIdentifier}"${
    moduleName && ', module="' + moduleName + '"'
  },result="error"}[5m])) / ${requestQuery}`;
}

type RequestCountQueryArguments = QueryArguments & {
  timeRangeInMinutes: number;
};

export function createRequestCountQuery({
  nodeIdentifier,
  nodeType,
  moduleName,
  timeRangeInMinutes,
}: RequestCountQueryArguments) {
  return `sum by (function, module) (increase(${nodeType}_calls_count_total{${nodeType}="${nodeIdentifier}"${
    moduleName && ', module="' + moduleName + '"'
  }}[${timeRangeInMinutes}m]))`;
}
