import { useState, ReactNode, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components";

import { usePingPrometheusQuery } from "../../api";
import { Icon, Button, TextInput, Checkbox } from "../UI";
import { NavBar } from "./NavBar";
import { addInstance, switchInstance } from "../../slices";
import { selectActivePrometheus } from "../../selectors";
import { useAppSelector } from "../../store";
import { PrometheusEnvironmentsList } from "../Settings";

export function ConfigurePrometheusScreen({
  children,
}: {
  children: ReactNode;
}) {
  const prometheusBaseUrl = useSelector(selectActivePrometheus)?.url ?? "";

  const hasNoPrometheusUrl = !prometheusBaseUrl;

  const [prometheusUrlFormValue, setPrometheusUrlFormValue] =
    useState(prometheusBaseUrl);

  return (
    <>
      {hasNoPrometheusUrl ? (
        <ConfigurePrometheus
          error={null}
          prometheusUrlFormValue={prometheusUrlFormValue}
          setPrometheusUrlFormValue={setPrometheusUrlFormValue}
        />
      ) : (
        <PrometheusConfiguredHappyPath
          prometheusBaseUrl={prometheusBaseUrl}
          prometheusUrlFormValue={prometheusUrlFormValue}
          setPrometheusUrlFormValue={setPrometheusUrlFormValue}
        >
          {children}
        </PrometheusConfiguredHappyPath>
      )}
    </>
  );
}

function PrometheusConfiguredHappyPath({
  prometheusBaseUrl,
  prometheusUrlFormValue,
  setPrometheusUrlFormValue,
  children,
}: {
  prometheusBaseUrl: string;
  prometheusUrlFormValue: string;
  setPrometheusUrlFormValue: (value: string) => void;
  children: ReactNode;
}) {
  const pingState = usePingPrometheusQuery();
  const { isLoading, isError, error, refetch } = pingState;

  useEffect(() => {
    if (prometheusBaseUrl) refetch();
  }, [prometheusBaseUrl, refetch]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <ConfigurePrometheus
          error={error}
          prometheusUrlFormValue={prometheusUrlFormValue}
          setPrometheusUrlFormValue={setPrometheusUrlFormValue}
        />
      ) : (
        children
      )}
    </>
  );
}

function Loading() {
  return (
    <Skeleton>
      <IconContainer>
        <BigIcon type="autometrics" width="36" height="36" />
      </IconContainer>
      <Heading>Loading...</Heading>
    </Skeleton>
  );
}

const ConfigurePrometheusForm = ({
  error,
  prometheusUrlFormValue,
  setPrometheusUrlFormValue,
}: {
  error: any;
  prometheusUrlFormValue: string;
  setPrometheusUrlFormValue: (value: string) => void;
}) => {
  const dispatch = useDispatch();

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        const id = Date.now().toString();

        dispatch(
          addInstance({
            url: prometheusUrlFormValue,
            id,
            name: "Local",
          })
        );
        dispatch(switchInstance(id));
      }}
    >
      <TextInputLabel>Enter your Prometheus URL</TextInputLabel>
      <StyledInput
        type="text"
        value={prometheusUrlFormValue}
        placeholder="e.g., http://localhost:9090"
        onChange={(event) => setPrometheusUrlFormValue(event.target.value)}
      />
      {error && <TextInputError>Something went wrong!</TextInputError>}
      <StyledButton type="submit">Configure Prometheus</StyledButton>
      {/* <RememberThis>
          Remember this url <Checkbox checked />
        </RememberThis> */}
    </form>
  );
};

function ConfigurePrometheus({
  error,
  prometheusUrlFormValue,
  setPrometheusUrlFormValue,
}: {
  error: any;
  prometheusUrlFormValue: string;
  setPrometheusUrlFormValue: (value: string) => void;
}) {
  const dispatch = useDispatch();
  const instances = useAppSelector((state) => state.environment.instances);

  return (
    <Skeleton>
      <Heading>Let&rsquo;s Get Configured</Heading>
      <ConfigurePrometheusForm
        prometheusUrlFormValue={prometheusUrlFormValue}
        setPrometheusUrlFormValue={setPrometheusUrlFormValue}
        error={error}
      />
      {instances.length > 0 && (
        <div>
          <p>Or try one of the following:</p>
          <PrometheusEnvironmentsList
            instances={instances}
            onEnvironmentClick={(instance) => {
              dispatch(switchInstance(instance.id));
            }}
          />
        </div>
      )}
    </Skeleton>
  );
}

const Skeleton = ({ children }: { children: ReactNode }) => (
  <Container>
    <NavBar />
    <Body>{children}</Body>
  </Container>
);

const Container = styled.div``;

const Body = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  max-width: 420px;
  margin: 0 auto;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const BigIcon = styled(Icon)`
  color: ${({ theme }) => theme.colorBase500};
  margin: 0 auto;
`;

const Heading = styled.h1`
  color: ${({ theme }) => theme.colorBase800};
  padding: 0;
  margin: 20px 0 0 0;
`;

const TextInputLabel = styled.label(
  ({ theme }) => css`
    font: ${theme.fontStudioStrongSmallShortHand};
    letter-spacing: ${theme.fontStudioStrongSmallLetterSpacing};
    color: ${theme.colorBase600};
    margin: 0;
  `
);

const TextInputError = styled(TextInputLabel).attrs({ as: "p" })`
  color: ${({ theme }) => theme.colorDanger500};
  margin: 4px 0 0 0;
`;

const StyledInput = styled(TextInput)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  margin-left: auto;
`;

const RememberThis = styled.div`
  font: ${({ theme }) => theme.fontStudioBodyCopySmallShortHand};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 8px;
`;
