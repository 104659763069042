import styled from "styled-components";

import { EnvironmentState, removeInstance } from "../../slices";
import { useAppDispatch } from "../../store";
import { IconButton, Icon } from "../UI";
import EnvironmentIcon from "./EnvironmentIcon.svg";

// HACK - extract Environment type from redux state
type Instances = EnvironmentState["instances"];

type Instance = Instances[0];

export function PrometheusEnvironmentsList({
  instances,
  activeInstance,
  onEnvironmentClick,
}: {
  instances: Instances;
  activeInstance?: Instance;
  onEnvironmentClick: (instance: Instance) => void;
}) {
  const dispatch = useAppDispatch();

  return (
    <EnvironmentsList>
      {instances.map((instance) => (
        <EnvironmentListItem
          active={activeInstance?.id === instance.id}
          key={instance.id}
          onClick={() => onEnvironmentClick && onEnvironmentClick(instance)}
        >
          <EnvironmentIcon width="40" height="40" />
          <EnvironmentDetails>
            <EnvironmentName>{instance.name}</EnvironmentName>
            <EnvironmentUrl>{instance.url}</EnvironmentUrl>
          </EnvironmentDetails>
          <EnvironmentActions>
            {/* <IconButton
              onClick={(event) => {
                event.stopPropagation();
                alert("TODO");
              }}
            >
              <StyledIcon type="pencil_simple" width="18" height="18" />
            </IconButton> */}
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                dispatch(removeInstance(instance));
              }}
            >
              <StyledIcon type="trash" width="18" height="18" />
            </IconButton>
          </EnvironmentActions>
        </EnvironmentListItem>
      ))}
    </EnvironmentsList>
  );
}

const EnvironmentsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

const HelperCopy = styled.div`
  font: ${({ theme }) => theme.fontStudioBodyCopyRegularShortHand};
  letter-spacing: ${({ theme }) =>
    theme.fontStudioBodyCopyRegularLetterSpacing};
  color: ${({ theme }) => theme.colorBase500};
`;

export const EnvironmentListItem = styled.div<{ active: boolean }>`
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 12px;
  border: solid 1px
    ${({ theme, active }) =>
      active ? theme.colorPrimary300 : theme.colorBase300};
  border-radius: 4px;

  &:hover {
    border-color: ${({ theme }) => theme.colorPrimary500};
    cursor: pointer;
  }
`;

export const EnvironmentDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

export const EnvironmentName = styled.div`
  font: ${({ theme }) => theme.fontStudioStrongRegularShortHand};
  letter-spacing: ${({ theme }) => theme.fontStudioStrongRegularLetterSpacing};
`;

export const EnvironmentUrl = styled(HelperCopy)`
  font-size: 12px;
`;

export const EnvironmentActions = styled.div`
  display: flex;
  gap: 4px;
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colorBase600};
`;
