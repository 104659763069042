import FocusTrap from "focus-trap-react";
import { AnimatePresence } from "framer-motion";
import { forwardRef, useMemo } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import useLockBodyScroll from "react-use/lib/useLockBodyScroll";

import { ModalContext } from "../UI";
import { Overlay } from "./Overlay";
import { ModalContent } from "./ModalContent";
import { RootState } from "../../state";

export function Modals() {
  const modals = useSelector((state: RootState) => state.ui.modals);
  useLockBodyScroll(modals.size > 0);

  const components: React.ReactNode = useMemo(() => {
    if (modals.size === 0) {
      return null;
    }

    return [...modals.entries()].map(
      ([key, { onRequestClose, onAfterClose, modal }]) => (
        <Overlay key={key} data-testid="modal-overlay">
          <ModalContext.Provider
            value={{ requestClose: onRequestClose, afterClose: onAfterClose }}
          >
            <FocusTrap>
              <RefCatcher>
                <ModalContent modal={modal} />
              </RefCatcher>
            </FocusTrap>
          </ModalContext.Provider>
        </Overlay>
      )
    );
  }, [modals]);

  return createPortal(
    <AnimatePresence>{components}</AnimatePresence>,
    document.body
  );
}

// `<FocusTrap />` insists on setting a ref on its child, which causes annoying
// warnings in the dev console. The `<RefCatcher />` simply catches and discards
// the ref...
const RefCatcher = forwardRef(function RefCatcher(
  { children }: { children: React.ReactNode },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _ref
): JSX.Element {
  return <>{children}</>;
});
