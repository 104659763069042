import { ReactNode, useContext } from "react";
import styled from "styled-components";

import { IconButton } from "../buttons";
import { Icon } from "../Icon";
import { ModalContext } from "./ModalContext";

type ModalHeaderProps = {
  className?: string;
  children: ReactNode;
  showCloseButton?: boolean;
};

const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colorBase300};
`;

const Title = styled.strong`
  font: ${({ theme }) => theme.fontNotebooksH3ShortHand};
`;

export function ModalHeader({
  className,
  children,
  showCloseButton = true,
}: ModalHeaderProps) {
  const { requestClose } = useContext(ModalContext);

  return (
    <Container className={className}>
      <Title id="modal-title">{children}</Title>
      {showCloseButton && requestClose && (
        <IconButton onClick={requestClose}>
          <Icon type="close" />
        </IconButton>
      )}
    </Container>
  );
}
