import styled from "styled-components";

import { useAppSelector, useAppDispatch } from "../../store";
import { HIDE_MODAL, SHOW_MODAL } from "../../actions";
import { switchInstance } from "../../slices";
import { Button, Icon } from "../UI";
import { PrometheusEnvironmentsList } from "./PrometheusEnvironmentsList";

export const Settings = () => {
  const dispatch = useAppDispatch();

  const { instances, selectedInstance } = useAppSelector(
    (state) => state.environment
  );

  const instanceCount = instances.length;

  const activeInstance = instances.find(
    (instance) => instance.id === selectedInstance
  );

  return (
    <ContainerOuter>
      <ContainerInner>
        <Header>
          <Heading>Settings</Heading>
          <HelperCopy>Manage your Prometheus settings here</HelperCopy>
        </Header>
        <EnvironmentsSection>
          <EnvironmentsHeader>
            <div>
              <EnvironmentsHeading>Prometheus Environments</EnvironmentsHeading>
              <HelperCopy>{instanceCount} URLs</HelperCopy>
            </div>
            <AddEnvironmentButton
              onClick={() => {
                const key = "environmentAdd";
                dispatch({
                  type: SHOW_MODAL,
                  payload: {
                    key,
                    modal: {
                      onRequestClose: () => {
                        dispatch({ type: HIDE_MODAL, payload: { key } });
                      },
                      modal: {
                        type: "environmentAdd",
                      },
                    },
                  },
                });
              }}
            >
              <Icon type="plus" width="12" height="12" />{" "}
              <span>Add Environment</span>
            </AddEnvironmentButton>
          </EnvironmentsHeader>
          <PrometheusEnvironmentsList
            instances={instances}
            activeInstance={activeInstance}
            onEnvironmentClick={(instance) => {
              dispatch(switchInstance(instance.id));
            }}
          />
        </EnvironmentsSection>
      </ContainerInner>
    </ContainerOuter>
  );
};

const ContainerOuter = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 20px;
  width: 100%;
`;

const ContainerInner = styled.div`
  max-width: 640px;
  width: 100%;
`;

const HelperCopy = styled.div`
  font: ${({ theme }) => theme.fontStudioBodyCopyRegularShortHand};
  letter-spacing: ${({ theme }) =>
    theme.fontStudioBodyCopyRegularLetterSpacing};
  color: ${({ theme }) => theme.colorBase500};
`;

const Header = styled.div`
  padding: 20px 0;
`;

const Heading = styled.h2`
  margin: 8px 0;
`;

const EnvironmentsSection = styled.div`
  border-top: solid 1px ${({ theme }) => theme.colorBase300};
  padding: 20px 0;
`;

const EnvironmentsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EnvironmentsHeading = styled.h3`
  margin: 4px 0;
`;

const AddEnvironmentButton = styled(Button)`
  font: ${({ theme }) => theme.fontStudioBodyCopySmallShortHand};
  letter-spacing: ${({ theme }) => theme.fontStudioBodyCopySmallLetterSpacing};
  font-weight: 500;

  gap: 4px;
  padding: 8px 12px;
`;
