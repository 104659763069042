import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ComponentProps,
  ForwardedRef,
  forwardRef,
} from "react";
import cx from "classnames";
import styled, { css, DefaultTheme, useTheme } from "styled-components";
import { Link } from "react-router-dom";

import { ButtonStyle } from "../../../types";

export const buttonStyling = css`
  --color: var(--button-normal-color);
  --backgroundColor: var(--button-normal-backgroundColor);

  outline: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition-property: background-color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  border-radius: ${({ theme }) => theme.borderRadius500};
  box-sizing: border-box;
  height: var(--icon-button-height, 20px);
  width: var(--icon-button-width, 20px);
  padding: var(--icon-button-padding, 2px);
  color: var(--color);
  background-color: var(--background);
  border: 1px solid var(--background);

  :focus,
  :hover,
  :active,
  .active {
    cursor: pointer;
  }

  :focus {
    border-color: ${({ theme }) => theme.colorPrimary500};
    outline: ${({ theme }) => theme.effectFocusOutline};

    --background: var(--button-focus-backgroundColor);
    --color: var(--button-focus-color);
  }

  &:disabled {
    cursor: default;

    --color: var(--button-disabled-color);
    --background: var(--button-disabled-backgroundColor);
  }

  &.active,
  &:active:not([data-dragging], [disabled]) {
    --background: var(--button-active-backgroundColor);
    --color: var(--button-active-color);
  }

  :hover:not([data-disabled][data-dragging], [disabled]) {
    --background: var(--button-hover-backgroundColor);
    --color: var(--button-hover-color);
    border: none;
  }

  & svg {
    flex: 0 0 var(--icon-button-icon-size);
    width: var(--icon-button-icon-size);
    height: var(--icon-button-icon-size);
  }
`;

type IconButtonStyle = Exclude<ButtonStyle, "baseInverted">;

type IconButtonColorSet = {
  color: string;
  backgroundColor: string;
};

type IconButtonTheme = {
  normal: IconButtonColorSet;
  hover: IconButtonColorSet;
  active: IconButtonColorSet;
  focus: IconButtonColorSet;
  disabled: IconButtonColorSet;
};

export function useIconButtonTheme(
  theme: DefaultTheme,
  buttonStyle: IconButtonStyle
): IconButtonTheme {
  switch (buttonStyle) {
    case "base":
      return {
        normal: {
          color: theme.colorBase800,
          backgroundColor: "transparent",
        },
        hover: {
          color: theme.colorBase800,
          backgroundColor: theme.colorBase300,
        },
        active: {
          color: theme.colorBackground,
          backgroundColor: theme.colorBase600,
        },
        focus: {
          color: theme.colorBase600,
          backgroundColor: theme.colorBackground,
        },
        disabled: {
          color: theme.colorBase500,
          backgroundColor: "transparent",
        },
      };

    case "primary":
      return {
        normal: {
          color: theme.colorPrimary500,
          backgroundColor: "transparent",
        },
        hover: {
          color: theme.colorBase600,
          backgroundColor: "transparent",
        },
        active: {
          color: theme.colorBackground,
          backgroundColor: theme.colorPrimary500,
        },
        focus: {
          color: theme.colorPrimary500,
          backgroundColor: theme.colorBackground,
        },
        disabled: {
          color: theme.colorBase500,
          backgroundColor: "transparent",
        },
      };

    case "secondary":
      return {
        normal: {
          color: theme.colorSecondary500,
          backgroundColor: "transparent",
        },
        hover: {
          color: theme.colorBase600,
          backgroundColor: "transparent",
        },
        active: {
          color: theme.colorBackground,
          backgroundColor: theme.colorSecondary500,
        },
        focus: {
          color: theme.colorSecondary500,
          backgroundColor: theme.colorBackground,
        },
        disabled: {
          color: theme.colorBase500,
          backgroundColor: "transparent",
        },
      };

    case "danger":
      return {
        normal: {
          color: theme.colorDanger600,
          backgroundColor: "transparent",
        },
        hover: {
          color: theme.colorDanger700,
          backgroundColor: "transparent",
        },
        active: {
          color: theme.colorBackground,
          backgroundColor: theme.colorDanger600,
        },
        focus: {
          color: theme.colorDanger600,
          backgroundColor: theme.colorBackground,
        },
        disabled: {
          color: theme.colorBase500,
          backgroundColor: "transparent",
        },
      };

    case "success":
      return {
        normal: {
          color: theme.colorSuccess500,
          backgroundColor: "transparent",
        },
        hover: {
          color: theme.colorSuccess600,
          backgroundColor: "transparent",
        },
        active: {
          color: theme.colorBackground,
          backgroundColor: theme.colorSuccess600,
        },
        focus: {
          color: theme.colorSuccess500,
          backgroundColor: theme.colorBackground,
        },
        disabled: {
          color: theme.colorBase500,
          backgroundColor: "transparent",
        },
      };

    case "warning":
      return {
        normal: {
          color: theme.colorWarning500,
          backgroundColor: "transparent",
        },
        hover: {
          color: theme.colorWarning600,
          backgroundColor: "transparent",
        },
        active: {
          color: theme.colorBackground,
          backgroundColor: theme.colorWarning600,
        },
        focus: {
          color: theme.colorWarning500,
          backgroundColor: theme.colorBackground,
        },
        disabled: {
          color: theme.colorBase500,
          backgroundColor: "transparent",
        },
      };
  }
}

const StyledButton = styled.button`
  ${buttonStyling}
`;

const StyledLink = styled(Link)`
  ${buttonStyling}
`;

const StyledAnchor = styled.a`
  ${buttonStyling}
`;

const sizes = {
  xSmall: {
    padding: "2px",
    width: "20px",
    height: "20px",
    iconSize: "8px",
  },
  small: {
    padding: "6px",
    width: "28px",
    height: "28px",
    iconSize: "16px",
  },
  default: {
    padding: "6px",
    width: "32px",
    height: "32px",
    iconSize: "20px",
  },
  large: {
    padding: "2px",
    height: "42px",
    width: "42px",
    iconSize: "32px",
  },
  xLarge: {
    padding: "2px",
    width: "50px",
    height: "50px",
    iconSize: "32px",
  },
};

type ButtonSize = keyof typeof sizes;

type StylingProps = {
  buttonStyle?: IconButtonStyle;
  buttonSize?: ButtonSize;
  active?: boolean;
};

type AsButton = ButtonHTMLAttributes<HTMLButtonElement> & {
  asElement?: "button";
};

type AsLink = ComponentProps<typeof StyledLink> & {
  asElement: "link";
};

type AsAnchor = AnchorHTMLAttributes<HTMLAnchorElement> & {
  asElement: "externalLink";
};

type IconButtonProps = AsButton | AsAnchor | AsLink;

export type Props = IconButtonProps & StylingProps;

function IconButton(props: Props, ref: ForwardedRef<HTMLElement>): JSX.Element {
  const {
    className = "",
    asElement = "button",
    buttonStyle = "base",
    buttonSize = "default",
    style = {},
    active = false,
    children,
    ...otherProps
  } = props;

  const theme = useTheme();
  const iconButtonTheme = useIconButtonTheme(theme, buttonStyle);

  const currentSize = sizes[buttonSize];

  const newStyle = {
    ...style,
    "--icon-button-padding": currentSize.padding,
    "--icon-button-width": currentSize.width,
    "--icon-button-height": currentSize.height,
    "--icon-button-icon-size": currentSize.iconSize,

    "--button-normal-color": iconButtonTheme.normal.color,
    "--button-normal-backgroundColor": iconButtonTheme.normal.backgroundColor,
    "--button-hover-color": iconButtonTheme.hover.color,
    "--button-hover-backgroundColor": iconButtonTheme.hover.backgroundColor,
    "--button-active-color": iconButtonTheme.active.color,
    "--button-active-backgroundColor": iconButtonTheme.active.backgroundColor,
    "--button-focus-color": iconButtonTheme.focus.color,
    "--button-focus-backgroundColor": iconButtonTheme.focus.backgroundColor,
    "--button-disabled-color": iconButtonTheme.disabled.color,
    "--button-disabled-backgroundColor":
      iconButtonTheme.disabled.backgroundColor,
  };

  const elementProps = {
    ...otherProps,
    ref,
    style: newStyle,
    className: cx(className, active && "active"),
    "aria-pressed": active,
  };

  switch (asElement) {
    case "link":
      return <StyledLink {...elementProps}>{children}</StyledLink>;

    case "externalLink":
      return <StyledAnchor {...elementProps}>{children}</StyledAnchor>;

    case "button":
      return <StyledButton {...elementProps}>{children}</StyledButton>;
  }
}

export default forwardRef(IconButton);
