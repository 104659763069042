import styled from "styled-components";
import { HTMLAttributes } from "react";

import { Icon } from "../Icon";

const maxBorderWidth = 1;
const innerSize = 16;
const outerSize = innerSize + maxBorderWidth + maxBorderWidth;

type Props = {
  checked: boolean;
} & HTMLAttributes<HTMLInputElement>;

export function Checkbox({
  checked = false,
  className,
  ...props
}: Props & HTMLAttributes<HTMLInputElement>) {
  return (
    <Container className={className}>
      <HiddenInput
        {...props}
        width={innerSize}
        height={innerSize}
        type="checkbox"
        checked={checked}
      />
      <CheckContainer>
        {checked && <CheckIcon width={innerSize} height={innerSize} />}
      </CheckContainer>
    </Container>
  );
}

const Container = styled.span<{ checked?: boolean }>`
  display: inline-block;
  position: relative;
  width: ${outerSize}px;
  min-width: ${outerSize}px;
  height: ${outerSize}px;
  border-radius: ${({ theme }) => theme.borderRadius500};
`;

const HiddenInput = styled.input`
  margin: 0;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
`;

const CheckContainer = styled.span`
  --color: ${({ theme }) => theme.colorBase600};

  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: ${({ theme }) => theme.borderRadius500};
  width: 100%;
  height: 100%;
  border: 2px solid var(--color);
  color: ${({ theme }) => theme.colorBackground};

  input:hover ~ & {
    --color: ${({ theme }) => theme.colorBase700};
  }

  input:focus ~ & {
    --color: ${({ theme }) => theme.colorBase600};
    outline: ${({ theme }) => theme.effectFocusOutline};
  }

  input:active ~ & {
    --color: ${({ theme }) => theme.colorForeground};
  }

  input:checked ~ & {
    --color: ${({ theme }) => theme.colorPrimary500};
    border-width: 1px;
    background: var(--color);
  }

  input:checked:hover ~ & {
    --color: ${({ theme }) => theme.colorPrimary400};
  }

  input:checked:active ~ & {
    --color: ${({ theme }) => theme.colorPrimary600};
  }
`;

const CheckIcon = styled(Icon).attrs({ type: "check" })`
  display: block;
  position: relative;
`;
