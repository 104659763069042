import styled, { css } from "styled-components";

import { Button, Icon } from "../../UI";
import { dispatch } from "../../../store";
import { toggleDatepickerVisibility } from "../../../actions";

export function NoData() {
  return (
    <NoDataContainer>
      <Icon type="autometrics" width={32} height={32} />

      <NoDataTitle>No data for this graph</NoDataTitle>
      <NoDataDescription>
        There is no data for your selected time range. Edit the time range or
        wait for Prometheus to collect more data.
      </NoDataDescription>

      <Button onClick={() => dispatch(toggleDatepickerVisibility())}>
        Edit time range
      </Button>
    </NoDataContainer>
  );
}

const NoDataContainer = styled.div`
  backdrop-filter: blur(4px);
  position: absolute;
  inset: 0 var(--container-spacing-negative) var(--container-spacing-negative);
  padding-top: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NoDataTitle = styled.h4(
  ({ theme }) => css`
    margin: 16px 0 8px;
    font: ${theme.fontStudioHeadingsH4ShortHand};
    letter-spacing: ${theme.fontStudioHeadingsH4LetterSpacing};
    font-style: italic;
  `
);

const NoDataDescription = styled.p(
  ({ theme }) => css`
    font: ${theme.fontStudioBodyCopySmallShortHand};
    letter-spacing: ${theme.fontStudioBodyCopySmallLetterSpacing};
    color: ${theme.colorBase600};
    max-width: 320px;
    margin: 0 0 20px;
  `
);
