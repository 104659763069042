import { useState } from "react";
import styled, { css } from "styled-components";
import { MetricsChart } from "fiberplane-charts";
import { useSearchParams } from "react-router-dom";

import {
  Container,
  INITIAL_TIMERANGE as DEFAULT_INITIAL_TIMERANGE,
  getBrandColorForIndex,
} from "./common";
import { DatePicker } from "../../DatePicker";
import { MetricCard } from "./MetricCard";
import { MetricDisplay } from "./MetricDisplay";
import { NoData } from "./NoData";
import { usePrometheusData } from "./usePrometheusData";

export function FunctionGraphsPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryFrom = searchParams.get("from");
  const queryTo = searchParams.get("to");

  const initialTimerange =
    queryFrom && queryTo
      ? { from: queryFrom, to: queryTo }
      : DEFAULT_INITIAL_TIMERANGE;
  const [timeRange, setTimeRange] = useState(initialTimerange);

  const [activeMetricIndex, setActiveMetricIndex] = useState(0);

  const { functionName, prometheusData } = usePrometheusData(timeRange);

  const currentMetric = prometheusData[activeMetricIndex];
  const currentMetricData = currentMetric?.data;
  const activeBrandColor = getBrandColorForIndex(activeMetricIndex);

  if (!currentMetric) {
    return null;
  }

  return (
    <FunctionsExplorerContainer>
      <PageHeading>Live metrics for your autometrics function</PageHeading>

      <PageSubheadingWrapper>
        <FunctionName>{functionName}</FunctionName>
        <DatePicker
          timeRange={timeRange}
          onChange={(value) => {
            setTimeRange(value);
            setSearchParams(value);
          }}
        />
      </PageSubheadingWrapper>

      <MetricCardsContainer>
        {prometheusData.map(({ title, metrics }, index) => {
          const latestMetric = metrics.find(
            ({ reportType }) => reportType === "latest"
          );
          if (!latestMetric) {
            return null;
          }

          const activeBrandColor = getBrandColorForIndex(index);
          const onClickHandler = () => setActiveMetricIndex(index);

          return (
            <MetricCard
              key={title}
              brandColor={activeBrandColor}
              isActive={activeMetricIndex === index}
              latestValue={latestMetric.value}
              latestValueUnit={latestMetric.unit}
              onClickHandler={onClickHandler}
              title={title}
            />
          );
        })}
      </MetricCardsContainer>

      <GraphContainer>
        <GraphDescriptionWrapper>
          <GraphTitle>{currentMetric.title}</GraphTitle>
          <GraphDescription>{currentMetric.description}</GraphDescription>
        </GraphDescriptionWrapper>

        {currentMetricData && currentMetricData.length > 0 && (
          <GraphObjectiveMetricsWrapper>
            {currentMetric.metrics?.map(({ reportType, value, unit }) => (
              <StyledMetricDisplay
                key={reportType}
                rate={value}
                unit={unit}
                report={reportType}
              />
            ))}
          </GraphObjectiveMetricsWrapper>
        )}

        <GraphWrapper>
          <MetricsChart
            graphType="line"
            stackingType="none"
            timeRange={timeRange}
            timeseriesData={currentMetricData ?? []}
            colors={[activeBrandColor]}
          />

          {!currentMetricData || (currentMetricData.length === 0 && <NoData />)}
        </GraphWrapper>
      </GraphContainer>
    </FunctionsExplorerContainer>
  );
}

const StyledMetricDisplay = styled(MetricDisplay)`
  --text-alternative-color: ${({ theme }) => theme.colorBase500};
`;

const FunctionsExplorerContainer = styled.div`
  --grid-spacing: 20px;
  --container-spacing: 20px;
  --container-spacing-negative: calc(var(--container-spacing) * -1);

  display: grid;
  gap: var(--grid-spacing);
  grid-template-rows: repeat(3, auto) 1fr;
  position: relative;
  padding: var(--container-spacing);
`;

const PageHeading = styled.h2(
  ({ theme }) => css`
    font: ${theme.fontStudioHeadingsH4ShortHand};
    letter-spacing: ${theme.fontStudioHeadingsH4LetterSpacing};
    margin: 0;
    line-height: 1.5;
  `
);

const PageSubheadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const FunctionName = styled.h3(
  ({ theme }) => css`
    margin: 0;
    padding: 6px 4px;

    border-radius: ${theme.borderRadius600};
    background: ${theme.colorBase200};
    color: ${theme.colorBase800};

    font: ${theme.fontNotebooksCodeShortHand};
    line-height: 1.2;
    font-weight: 500;
    font-size: 20px;
  `
);

const MetricCardsContainer = styled.div`
  --objectives-container-scroll-offset: 15px;

  display: flex;
  gap: var(--grid-spacing);
  margin: 0 var(--container-spacing-negative)
    calc(var(--objectives-container-scroll-offset) * -1);
  padding: 0 var(--container-spacing) var(--objectives-container-scroll-offset);
  overflow-x: scroll;

  /*
    FIXME (Oscar): not so pretty but keeping it here for later improvemements
  */
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: var(--container-spacing);
    height: 100%;
    background: linear-gradient(
      to right,
      rgb(255 255 255 / 70%) 0%,
      rgb(255 255 255 / 0) 100%
    );
  }

  &::after {
    left: auto;
    right: 0;
    background: linear-gradient(
      to left,
      rgb(255 255 255 / 70%) 0%,
      rgb(255 255 255 / 0) 100%
    );
  }
`;

const GraphContainer = styled(Container)`
  display: grid;
  gap: var(--grid-spacing);
  grid: "description" auto "metrics" auto "graph" 1fr;
`;

const GraphTitle = styled(PageHeading).attrs({ as: "h3" })`
  line-height: 1.2;
  margin-bottom: 12px;
`;

const GraphDescription = styled.p(
  ({ theme }) => css`
    font: ${theme.fontStudioBodyCopySmallShortHand};
    letter-spacing: ${theme.fontStudioBodyCopySmallLetterSpacing};
    margin: 0;
    color: ${theme.colorBase600};
  `
);

const GraphDescriptionWrapper = styled.div`
  grid-area: description;
`;

const GraphObjectiveMetricsWrapper = styled.div`
  grid-area: metrics;
  display: flex;
  gap: 40px;
`;

const GraphWrapper = styled.div`
  grid-area: graph;
  position: relative;
`;
