import { Thunk } from "../store";
import { ConfirmationDialogModalProps, ConfirmationDialogType } from "../types";
import { HIDE_MODAL, SHOW_MODAL } from "../actions";

export const showConfirmationDialog =
  (key: string, modalProps: ConfirmationDialogModalProps): Thunk =>
  (dispatch) => {
    const onRequestClose = () => {
      dispatch(hideConfirmationDialog(key));
    };

    const modal: ConfirmationDialogType = {
      ...modalProps,
      type: "confirmationDialog",
    };

    dispatch({
      type: SHOW_MODAL,
      payload: {
        key,
        modal: { onRequestClose, modal },
      },
    });
  };

export const hideConfirmationDialog =
  (key: string): Thunk =>
  (dispatch) => {
    dispatch({ type: HIDE_MODAL, payload: { key } });
  };

