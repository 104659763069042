import styled from "styled-components";

// import { AppSideMenu } from "./AppSideMenu";

type Props = {
  children: React.ReactNode;
};

export function AppSkeleton(props: Props): JSX.Element {
  return (
    <AppContainer>
      {/* <AppSideMenu /> */}
      {props.children}
    </AppContainer>
  );
}

const AppContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
`;
