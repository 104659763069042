import { memo, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { AppSkeleton } from "../AppSkeleton";
import { FunctionsExplorer } from "../FunctionsExplorer";
import { Settings } from "../Settings";
import { NavBar } from "./NavBar";
import { useAppDispatch } from "../../store";
import { appInitThunk } from "../../thunks";
import { Modals } from "../Modals";
import { ConfigurePrometheusScreen } from "./ConfigurePrometheusScreen";

// TODO - Implement 404
function NotFound() {
  return <div>404 - Not Found</div>;
}

// TODO - Implement this when no function data found
function EmptyState() {
  return <div>Empty State</div>;
}

export const App = memo(function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(appInitThunk());
  }, [dispatch]);
  return (
    <ConfigurePrometheusScreen>
      <AppSkeleton>
        <NavBar />
        <main
          id="main"
          data-testid="main"
          style={{ height: "calc(100% - 60px)" }}
        >
          <Routes>
            <Route path="/" element={<FunctionsExplorer />} />
            <Route path="/functions/*" element={<FunctionsExplorer />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Modals />
      </AppSkeleton>
    </ConfigurePrometheusScreen>
  );
});
