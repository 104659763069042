import styled from "styled-components";
import { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  align?: CSSStyleDeclaration["justifyContent"];
};

const ButtonBar = styled.div<Pick<Props, "align">>`
  display: flex;
  justify-content: ${({ align = "flex-end" }) => align};
  align-items: center;
  gap: 6px;
`;

export default ButtonBar;
