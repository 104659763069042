import { useParams } from "react-router-dom";

import { FunctionTreeNodeData } from "../../../types";

/**
 * Hook that determines if a node is active based on the current URL. It checks
 * its children for matching function names.
 */
export function useIsActiveNode(node: FunctionTreeNodeData) {
  // Currently FunctionTree doesn't have access to the named routes, so we have
  // to use the wildcard route and parse the params ourselves.
  const params = useParams()["*"];
  const [moduleName, functionName] = params?.split("/") ?? [null, null];

  if (!moduleName || !functionName || !params) {
    return false;
  }

  const isActive =
    node.name === moduleName &&
    node?.children?.some((c) => c.name === functionName);

  return !!isActive;
}
