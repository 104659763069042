import { useContext, useEffect } from "react";
import styled, { css } from "styled-components";

import { useHandler } from "../../hooks";
import type { ConfirmationDialogModalProps } from "../../types";
import { Button, Modal, ModalContext } from "../UI";

export function ConfirmationDialog({
  children,
  iconVisual,
  onCancel,
  onCancelLabel = "Cancel",
  onConfirm,
  onConfirmButtonStyle,
  onConfirmLabel = "Confirm",
  title,
}: ConfirmationDialogModalProps) {
  const { requestClose } = useContext(ModalContext);

  const handleOnCancel = () => {
    onCancel?.();
    requestClose();
  };

  const handleOnConfirm = () => {
    onConfirm?.();
    requestClose();
  };

  const onKeyDown = useHandler((event: KeyboardEvent) => {
    if (event.key === "Enter") {
      handleOnConfirm();
    }
  });

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [onKeyDown]);

  return (
    <ConfirmationDialogModal data-testid="confirmation-modal">
      <ConfirmationDialogContent>
        {/* <IconVisual type={iconVisual} /> */}

        <ConfirmationDialogContentWrapper>
          <Title>{title}</Title>
          <Paragraph>{children}</Paragraph>
        </ConfirmationDialogContentWrapper>

        <ConfirmationDialogContentWrapper>
          <Button
            onClick={handleOnConfirm}
            data-testid="confirm"
            type="submit"
            buttonStyle={onConfirmButtonStyle}
          >
            {onConfirmLabel}
          </Button>

          <Button
            onClick={handleOnCancel}
            buttonStyle="baseInverted"
            type="button"
          >
            {onCancelLabel}
          </Button>
        </ConfirmationDialogContentWrapper>
      </ConfirmationDialogContent>
    </ConfirmationDialogModal>
  );
}

const ConfirmationDialogModal = styled(Modal)`
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h3(
  ({ theme }) => css`
    font: ${theme.fontStudioHeadingsH3ShortHand};
    line-height: 24px;
    text-align: center;
    padding: 0;
    margin: 0;
  `
);

const Paragraph = styled.p(
  ({ theme }) => css`
    font: ${theme.fontStudioBodyCopyRegularShortHand};
    color: ${theme.colorBase600};
    padding: 0;
    margin: 0;
  `
);

const ConfirmationDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const ConfirmationDialogContentWrapper = styled.div`
  display: grid;
  grid-gap: 8px;
  width: 100%;
`;
