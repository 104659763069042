import styled, { css } from "styled-components";
import { forwardRef, ForwardedRef, InputHTMLAttributes, useState } from "react";

const ICON_SIZE = 16;
const LINE_HEIGHT = 16;
const PADDING_Y = 8;
const PADDING_X = 12;
const PREFIX_PADDING = 7;

const iconStyle: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  marginTop: ICON_SIZE / -2,
};

export type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  leadingIcon?: React.ElementType<React.SVGProps<SVGSVGElement>>;
  trailingIcon?: React.ElementType<React.SVGProps<SVGSVGElement>>;
};

export const TextInput = forwardRef(function TextInput(
  props: TextInputProps,
  ref: ForwardedRef<HTMLInputElement>
): JSX.Element {
  const {
    prefix,
    className,
    leadingIcon: LeadingIcon,
    trailingIcon: TrailingIcon,
    style,
    ...rest
  } = props;

  const [prefixRef, setPrefixRef] = useState<HTMLSpanElement | null>(null);

  if (!prefix && !LeadingIcon && !TrailingIcon) {
    return (
      <StyledInput {...rest} className={className} ref={ref} style={style} />
    );
  }

  const { margin, ...innerStyle } = style || {};

  const prefixStyle = {
    ...iconStyle,
    left: PREFIX_PADDING,
  };

  let paddingLeft = LeadingIcon ? ICON_SIZE + PADDING_X * 2 : PADDING_X;
  if (prefix && prefixRef) {
    paddingLeft += prefixRef.getBoundingClientRect().width - PREFIX_PADDING;
  }

  return (
    <StyledContainer className={className}>
      {LeadingIcon && (
        <LeadingIcon
          height={ICON_SIZE}
          width={ICON_SIZE}
          style={{ ...iconStyle, left: iconStyle.left ?? PADDING_X }}
        />
      )}
      {prefix && (
        <Prefix ref={setPrefixRef} style={prefixStyle}>
          {prefix}
        </Prefix>
      )}
      <StyledInput
        {...rest}
        ref={ref}
        style={{
          ...innerStyle,
          paddingLeft,
          paddingRight: TrailingIcon ? ICON_SIZE + PADDING_X * 2 : PADDING_X,
          verticalAlign: "bottom",
          flex: 1,
        }}
      />
      {TrailingIcon && (
        <TrailingIcon
          height={ICON_SIZE}
          width={ICON_SIZE}
          style={{ ...iconStyle, right: iconStyle.right ?? PADDING_X }}
        />
      )}
    </StyledContainer>
  );
});

const Prefix = styled.span(
  ({ theme }) => css`
    font: ${theme.fontNotebooksSmallShortHand};
    line-height: ${LINE_HEIGHT}px;
    padding: ${PADDING_Y} 0;
    user-select: none;
  `
);

const StyledContainer = styled.div`
  --placeholderColor: ${({ theme }) => theme.colorBase500};

  position: relative;
  flex: 1;
  display: flex;
  color: var(--placeholderColor);
`;

export const StyledInput = styled.input<{ prefix?: string }>`
  box-sizing: border-box;
  font: ${({ theme }) => theme.fontStudioStrongSmallFontStyle};
  line-height: ${LINE_HEIGHT}px;
  outline: none;
  padding: 7px 11px;
  border-radius: ${({ theme }) => theme.borderRadius400};
  background: ${({ theme }) => theme.colorBase200};
  border: 1px solid ${({ theme }) => theme.colorBase200};
  color: ${({ theme }) => theme.colorBase800};

  --placeholderColor: var(--placeholderColor);

  &::placeholder {
    color: var(--placeholderColor);
  }

  &:hover {
    background: ${({ theme }) => theme.colorBase300};
    border-color: ${({ theme }) => theme.colorBase300};
  }

  &:active {
    background: ${({ theme }) => theme.colorBackground};
    border-color: ${({ theme }) => theme.colorBase600};
  }

  &:focus-visible,
  &:focus {
    outline: ${({ theme }) => theme.effectFocusOutline};
    background: ${({ theme }) => theme.colorBackground};
    border-color: ${({ theme }) => theme.colorPrimary600};
  }

  &[aria-invalid="true"],
  :invalid {
    background: ${({ theme }) => theme.colorDanger100};
    border-color: ${({ theme }) => theme.colorDanger500};
  }

  &:disabled,
  &[readonly] {
    outline: none;
    color: ${({ theme }) => theme.colorBase500};
    background: ${({ theme }) => theme.colorBase300};
    border-color: ${({ theme }) => theme.colorBase300};
  }
`;
